.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	display: flex;
	justify-content: center;
	
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		padding:0.5em 0.5em;
		
		&:hover {
			color:$action-hover;
		}
	}
	
	li.pager-next,
	li.pager-last,
	li.pager-previous,
	li.pager-first {
		width: 50px;
		height: 50px;
		padding: 0;
		margin: 0 5px;
		
		a {
			display: block;
			background: $slick-default-prev-bg; //transparent $pager-green-previous no-repeat scroll 50% 50% / 48px 48px;
			width: 50px;
			height: 50px;
			text-indent: -9999px;
		}
	}
	
	// li.pager-item.pager-next,
	li.pager-next {
		margin-left: $horz-space;
		a {
			background-image: $pager-red-next;
			
			&:hover {
				background-image: $pager-blue-next;
			}
		}
	}
	// li.pager-item.pager-last,
	li.pager-last  {
		a {
			background-image: $pager-red-last;
			
			&:hover {
				background-image: $pager-blue-last;
			}
		}
	}
	// li.pager-item.pager-previous,
	li.pager-previous {
		margin-right: $horz-space;
		a {
			background-image: $pager-red-previous;
			
			&:hover {
				background-image: $pager-blue-previous;
			}
		}
	}
	// li.pager-item.pager-first,
	li.pager-first {
		a {
			background-image: $pager-red-first;
			
			&:hover {
				background-image: $pager-blue-first;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0;
		font-size: 1.222rem;
		// font: $title-font-family;
		font: $button-font;
		font-weight: normal;
		font-size: 1.111rem;
		color: $action-default;
		padding: 0.75em 0.5em;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
	li.pager-current {
		color:$black_coral;
	}
}

.palette-alt-3 {
	.item-list  ul.pager {
		// li.pager-item.pager-next,
		li.pager-next {
			a {
				background-image: $pager-white-next;
				
				&:hover {
					background-image: $pager-dark-next;
				}
			}
		}
		// li.pager-item.pager-last,
		li.pager-last  {
			a {
				background-image: $pager-white-last;
				
				&:hover {
					background-image: $pager-dark-last;
				}
			}
		}
		// li.pager-item.pager-previous,
		li.pager-previous {
			a {
				background-image: $pager-white-previous;
				
				&:hover {
					background-image: $pager-dark-previous;
				}
			}
		}
		// li.pager-item.pager-first,
		li.pager-first {
			a {
				background-image: $pager-white-first;
				
				&:hover {
					background-image: $pager-dark-first;
				}
			}
		}
		li,
		li.pager-item {
		
		a {
			color:white;
			
			&:hover {
				color:$skibeloff;
			}
		}
		}
		li.pager-current {
			color:$outer_space;
		}
	}
}

.palette-highlight {
	.item-list  ul.pager {
		// li.pager-item.pager-next,
		li.pager-next {
			a {
				background-image: $pager-white-next;
				
				&:hover {
					background-image: $pager-blue-next;
				}
			}
		}
		// li.pager-item.pager-last,
		li.pager-last  {
			a {
				background-image: $pager-white-last;
				
				&:hover {
					background-image: $pager-blue-last;
				}
			}
		}
		// li.pager-item.pager-previous,
		li.pager-previous {
			a {
				background-image: $pager-white-previous;
				
				&:hover {
					background-image: $pager-blue-previous;
				}
			}
		}
		// li.pager-item.pager-first,
		li.pager-first {
			a {
				background-image: $pager-white-first;
				
				&:hover {
					background-image: $pager-blue-first;
				}
			}
		}
		li,
		li.pager-item {
		
		a {
			color:white;
			
			&:hover {
				color:$action-hover;
			}
		}
		}
		li.pager-current {
			color:$action-hover;
		}
	}
}