// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #404647; /* $outer_space */
$secondary         : #51626F; /* $black_coral */


// /* MAIN PALETTE */ //
$skibeloff           : #006778; //DARK
$vivid_burgundy      : #A71930; //RED


// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa;

$black             : #000;
$grey              : #666;
$white             : #FFF;

$fulvous            : #E98300;
$apple_green        : #7AB800;
$turquoise_surf     : #00ADD0;

$outer_space        : #404647;
$black_coral        : #51626F;
$aurometalsaurus    : #5F7383;
$raisin_black       : #231f20;
$gainsboro          : #D7D9DC;
$blue_shapphire     : #135D69;
$platinum           : #EEE8EA;


// /* BACKGROUNDS */ //
$midnight_green     : #004A56;
$light_cyan         : #E0F5F9;
$white_smoke        : #F5F2F3;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $vivid_burgundy;
$action-hover        : $turquoise_surf;
$action-active       : $turquoise_surf;
$action-active-hover : $turquoise_surf;

$action-alt-1-default      : $outer_space;
$action-alt-1-hover        : white;
$action-alt-1-active       : white;
$action-alt-1-active-hover : white;

$action-alt-2-default      : white;
$action-alt-2-hover        : $turquoise_surf;
$action-alt-2-active       : $turquoise_surf;
$action-alt-2-active-hover : $turquoise_surf;


// /* SHADOWS */ //
$box-shadow : none; //0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : none; //1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : none; //1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : none; //1px solid $tertiary-border-color;

$base-border                   : none; //1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-padding            : .5rem 1rem;
$table-header-font        : 1.444em/1.33333333333333 $title-font-family;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;


$table-border             : 1px solid $aurometalsaurus;
$table-bg-color           : white;
$table-hover-bg-color     : lighten($light_cyan, 10%);
$table-cell-border        : 1px solid $aurometalsaurus;
$table-cell-text          : $secondary;
$table-caption-bg-color   : $aurometalsaurus;
$table-caption-text-color : $primary;
$table-caption-border     : 1px solid $aurometalsaurus;
$table-header-bg-color    : $white_smoke;
$table-header-border      : 1px solid $aurometalsaurus;
$table-header-cell-border : 1px solid $aurometalsaurus;
$table-header-text        : $primary;

$table-alt-1-header-bg-color    : $light_cyan;

$table-alt-2-border             : 2px solid $aurometalsaurus;
$table-alt-2-bg-color           : $midnight_green;
$table-alt-2-hover-bg-color     : lighten($midnight_green, 10%);
$table-alt-2-cell-border        : 1px solid $aurometalsaurus;
$table-alt-2-cell-text          : white;
$table-alt-2-header-bg-color    : $skibeloff;
$table-alt-2-header-border      : 2px solid white;
$table-alt-2-header-cell-border : 1px solid white;
$table-alt-2-header-text        : white;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow;
$form-border-color     : $gainsboro;
$form-border           : 1px solid $form-border-color;
$form-border-radius    : 25px;
$form-alt-border-radius   : 4px;
$form-alt1-border-radius  : 4px 25px 25px 4px;

$exposed-form-bg       : $light_cyan;
$exposed-form-field-bg : white;
$exposed-form-alt-bg       : white;
$exposed-form-alt-field-bg : $light_cyan;

$button-border         : 4px solid $action-default;
$button-border-radius  : 0;

$button-border-color         : $action-default;
$button-bg                   : transparent;
$button-text                 : $action-default;
$button-hover-border-color   : $action-hover;
$button-hover-bg             : transparent;
$button-hover-text           : $action-hover;

$button-alt-border-color         : $skibeloff;
$button-alt-bg                   : transparent;
$button-alt-text                 : $skibeloff;
$button-alt-hover-border-color   : $action-hover; 
$button-alt-hover-bg             : transparent;
$button-alt-hover-text           : $action-hover;

$button-alt-2-border-color         : white;
$button-alt-2-bg                   : transparent;
$button-alt-2-text                 : white;
$button-alt-2-hover-border-color   : $skibeloff;
$button-alt-2-hover-bg             : transparent;
$button-alt-2-hover-text           : $skibeloff;

$button-alt-3-border-color         : white;
$button-alt-3-bg                   : transparent;
$button-alt-3-text                 : white;
$button-alt-3-hover-border-color   : $action-hover;
$button-alt-3-hover-bg             : transparent;
$button-alt-3-hover-text           : $action-hover;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;
