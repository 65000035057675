.section-row.section-with-promo {
	padding: 0 0 20px;
	@include media($normal) {
		padding: 0;
	}
	background-color: transparent !important;
	position: relative;
	min-height: 380px;
	display: flex;
	width: 100%;
	margin: 20px 0 0;
  align-items: center;	
	min-height: 260px;
	
	@include media($normal) {
		min-height: 360px;
	}
	
	.section-inner {
    overflow: visible;
   	padding: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
    
		//display: table-cell;
		//vertical-align: middle;
		
		.view-promo {
			@include media($normal) {
				background: $black_coral;
			}

			.promo {
				max-width: 100%;
    		margin-left: auto;
    		margin-right: auto;
				text-align: center;

				.card-image {
					width: 280px;
					height: 280px;
					border-radius: 50%;
					overflow: hidden;
					padding: 0;
					display: inline-block;
					
					@include media($normal) {
						position: absolute;
						width: 360px;
						height: 360px;
						top: calc(50% - 180px);
						right: 5%;
					}
					
					@media (min-width:1400px) {
						right: calc(50% - 630px);
					}
					
					@media (min-width:1600px) {
						right: calc(50% - 700px);
					}
					
					.promo-image {
						position: relative;
						
						&:after {
							display: block;
					    content: "";
					    height: 280px;
					    width: 280px;
					    z-index: 5;
							position: relative;
							background: $promo-circle no-repeat scroll 50% 50% / 280px 280px transparent;
							
							@include media($normal) {
								background-size: 360px 360px;
								height: 360px;
								width: 360px;
							}
						}
					}
					
					picture {
						position: absolute;
				    width: 250px;
				    height: 250px;
						top: 15px;
						left: 15px;
				    border-radius: 50%;
				    overflow: hidden;
				    z-index: 2;						
						
						@include media($normal) {
							height: 320px;
							width: 320px;
					    top: 20px;
					    left: 20px;
						}
					}
				}

				a.card-promo-text {
					padding: 150px 10% 40px;
					background-color: $skibeloff;
					background-position: 50% -35px, 50% 50px, 50% calc(100% + 45px);
    			background-size: 1480px, calc(100% - 40px), 1480px;
					background-repeat: repeat-x, no-repeat, repeat-x;	
					display: block;
   			 	color: white;
					text-align: left;					
					margin-top: -130px;
					text-decoration: none !important;
					
					@media (min-width:840px) {
						background-size: 1480px, 800px, 1480px;
					}
					
					@include media($normal) {
						padding: 50px calc(5% + 480px) 50px 5%;
						margin-top: 0px;
   					//min-height: 270px;
						background-position: 50% -35px, 20px 50px, 50% calc(100% + 45px);						
					}
					
					@media (min-width:1400px) {
						padding: 50px calc(50% - 140px) 50px calc(50% - 630px);
					}
					
					@media (min-width:1600px) {
						background-position: 50% -35px, calc(50% - 340px) 50px, 50% calc(100% + 45px);
						padding: 50px calc(50% - 140px) 50px calc(50% - 700px);
					}
					
					&:hover {					
						//background-color: $action-hover;
	   			 	color: $action-hover;
						transition: background  300ms linear 0s;
					}

					.promo-title {
						font-size: 2.667rem;
						line-height: 1.2;
    				margin-bottom: 1rem;
						font-family: $title-font-family;
						
						@include media($normal) {
							font-size: 3.889rem;
						}
					}

					.promo-description p {
						font-size: 1.222rem;
						margin: 0;
						font-weight: normal;
						padding-right: 45px;
						display: inline;
					}
					
					// @media (min-width:1400px) {
					// 	.card-title-field, .card-description {
					// 		max-width: 765px;
					// 	}
					// }
				}
			}
		}
	}
}