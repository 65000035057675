form#mc-embedded-subscribe-form {
  padding: 0;
  text-align: left;
  
  #mc_embed_signup_scroll {
    display: flex;
    
    .button {
      background: none !important;
      padding: 0;
      height: auto;
    }
    
    input.email {
      margin: 0 10px 0 0;
    }
  }
}

footer {
  form#mc-embedded-subscribe-form  { 
    
    #mc_embed_signup_scroll {
      display: flex;      
      
      @include media($narrow) {
        flex-direction: column;
      }
      
      @include media($wide) {
        flex-direction: row;
      }
       
      input.email {
        background-color: white;
        color: $primary;
        
        @include media($narrow) {
          margin: 0 0 10px 0;
        }
        
        @include media($wide) {
          margin: 0 10px 0 0;
        }
        
        &::placeholder {
        	font-size: 0.777rem !important;
        	color:$aurometalsaurus !important;
        	padding: 0 !important;
        }
      }
    }
  }
}