/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 30px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		color: $action-default;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		/*border: 1px solid #d0d0d0;*/
		margin-bottom: -1px;
		cursor: pointer;
		font:$card-title-font;
		box-sizing: border-box;
		background: $ico-expand-red no-repeat scroll 0 12px / 16px 16px transparent;
		
		p {
			margin-bottom: 0;
		}
		
		&:hover {
			color: $action-hover;
			background: $ico-expand-blue no-repeat scroll 0 12px / 16px 16px transparent;
		}

		&.active {
			//font-weight: bold;
			background: $ico-collapse-red no-repeat scroll 0 12px / 16px 16px transparent;
			
			&:hover {
				background: $ico-collapse-blue no-repeat scroll 0 12px / 16px 16px transparent;
			}
		}
	}
}

.palette-alt-3 .view-faqs {
	.card-qa-question {
		color: $outer_space;
		background: $ico-expand-dark no-repeat scroll 0 12px / 16px 16px transparent;

		&:hover {
			color: white;
			background: $ico-expand-white no-repeat scroll 0 12px / 16px 16px transparent;
		}
		
		&.active {
			//color: $skibeloff;
			background: $ico-collapse-dark no-repeat scroll 0 12px / 16px 16px transparent;
			
			&:hover {
				//color: white;
				background: $ico-collapse-white no-repeat scroll 0 12px / 16px 16px transparent;
			}
		}
	}
}

.palette-highlight .view-faqs {
	.card-qa-question {
		color: white;
		background: $ico-expand-white no-repeat scroll 0 12px / 16px 16px transparent;

		&:hover {
			color: $turquoise_surf;
			background: $ico-expand-blue no-repeat scroll 0 12px / 16px 16px transparent;
		}
		
		&.active {
			//color: white;
			background: $ico-collapse-white no-repeat scroll 0 12px / 16px 16px transparent;
			
			&:hover {
				//color: $turquoise_surf;
				background: $ico-collapse-blue no-repeat scroll 0 12px / 16px 16px transparent;
			}
		}
	}
}