#site-footer {
	.outer-wrapper {
		padding:0 $horz-space/2;
	}

	h2 {
		color:$footer-first-text;
		text-transform: none;
		font-size: 1.889rem;

		&:after {
			content: "";
			display: block;
			height: 6px;
			width: 80px;
			background-color: white;
			margin: $vert-space/4 0 $vert-space*1.5;
		}
	}

	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}

	@include button-link-alt2();

	.card-follow-us-on ul li { display: inline-block; }

	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		@include palette-footer-first-slick-controls;

		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}

		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}

		a {
			color:$footer-first-action;
			text-decoration: underline;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;

		a {
			color:$footer-second-action;
			text-decoration: underline;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}

		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;

			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
		}

	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size:0.778rem;

		a {
			color:$action-default;
			&:hover {
				color:$footer-third-action-hover;
			}
		}

		#manta-ray-media {
			float: none;
			@include media(880px) {
				float: right;
			}
			a {
				color: $action-default;
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				&.menu {
					margin-top:0;
				}
				li {
					display: inline-block;
					margin-right: 15px;
					padding-right: 20px;
					border-right: 1px solid $aurometalsaurus;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin: 0 10px 10px 0;
		text-indent: -9999px;

		a {
			background: $ico-web-white no-repeat 50% 50% / 19px 19px transparent;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			position: relative;

			&:after {
				content: "";
				position: absolute;
				display: block;
				top: 0;
				left: 0;
				background: $social-ring-white no-repeat 50% 50% / 40px 40px transparent;
				height: 40px;
    		width: 40px;
			}

			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background: $ico-web-over no-repeat 50% 50% / 19px 19px transparent;

				&:after {
					background: $social-ring-blue no-repeat 50% 50% / 40px 40px transparent;
				}
			}
		}

		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter-white no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px transparent;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-linkedin-over no-repeat scroll 50% 50% / 19px 18px transparent;
			}
		}

		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"],
		a[href^="http://twitter.com"] {
			background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px transparent;
			&:hover {
				background: $ico-twitter-over no-repeat scroll 50% 50% / 20px 20px transparent;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-white no-repeat scroll 50% 50% / 18px 18px transparent;
			&:hover {
				background: $ico-facebook-over no-repeat scroll 50% 50% / 18px 18px transparent;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-gplus-over no-repeat scroll 50% 50% / 19px 18px transparent;
			}
		}

		/* Institutional profile */
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution-white no-repeat 50% 50% / 19px 19px transparent;
        &:hover {
          background: $ico-institution-over no-repeat 50% 50% / 19px 19px transparent;
        }
   	}

  	/* 		Research Gate */
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
			background: $ico-researchgate-white no-repeat 50% 50% / 19px 18px transparent;
			&:hover {
				background: $ico-researchgate-over no-repeat 50% 50% / 19px 18px transparent;
			}
    }

 /* Google Scholar */
    .g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar-white no-repeat 50% 50% / 17px 19px transparent;

      &:hover {
        background: $ico-gscholar-over no-repeat 50% 50% / 17px 19px transparent;
      }
    }

		/* orcid */
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid-white no-repeat 50% 50% / 18px 18px transparent;
      &:hover {
        background: $ico-orcid-over no-repeat 50% 50% / 18px 18px transparent;
      }
    }

		/* YouTube */
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube-white no-repeat 50% 50% / 19px 13px transparent;

      &:hover {
        background: $ico-youtube-over no-repeat 50% 50% / 19px 13px transparent;
      }
    }

		/* flickr */
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr-white no-repeat 50% 50% / 18px 14px transparent;

      &:hover {
        background: $ico-flickr-over no-repeat 50% 50% / 18px 14px transparent;
      }
		}

		/* instagram */
    .instagram a,
    a[href^="https://www.instagram.com"],
		a[href^="http://www.instagram.com"],
		a[href^="https://instagram.com"],
		a[href^="http://instagram.com"] {
      background:$ico-instagram-white no-repeat 50% 50% / 18px 18px transparent;

      &:hover {
        background: $ico-instagram-over no-repeat 50% 50% / 18px 18px transparent;
      }
    }
	}

	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
}
