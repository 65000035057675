// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $pager-red-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-default-prev-hover-bg      				: transparent $pager-blue-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-default-next-bg            				: transparent $pager-red-next no-repeat scroll 50% 50% / 48px 48px;
$slick-default-next-hover-bg      				: transparent $pager-blue-next no-repeat scroll 50% 50% / 48px 48px;
$slick-default-dot-border         				: 2px solid $action-default;
$slick-default-dot-hover-border    				: 2px solid $action-hover;
$slick-default-dot-active-bg-color 				: $black_coral;
$slick-default-dot-active-hover-bg-color 	: $black_coral;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: transparent $pager-white-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-prev-hover-bg         				: transparent $pager-dark-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-next-bg               				: transparent $pager-white-next no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-next-hover-bg         				: transparent $pager-dark-next no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-dot-default-border    				: 2px solid white;
$slick-alt-1-dot-hover-border      				: 2px solid $skibeloff;
$slick-alt-1-dot-active-bg-color   				: $outer_space;
$slick-alt-1-dot-active-hover-bg-color 		: $outer_space;


$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: transparent $pager-white-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-prev-hover-bg         				: transparent $pager-blue-previous no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-next-bg               				: transparent $pager-white-next no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-next-hover-bg         				: transparent $pager-blue-next no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-dot-default-border    				: 2px solid white;
$slick-alt-2-dot-hover-border      				: 2px solid $action-hover;
$slick-alt-2-dot-active-bg-color   				: white;
$slick-alt-2-dot-active-hover-bg-color 		: $action-hover;


$slick-alt-3-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-3-controls-bg         					: none; // used on twitter views etc
$slick-alt-3-prev-bg               				: $midnight_green $arrow-white-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-3-prev-hover-bg         				: $action-hover $arrow-white-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-3-next-bg               				: $midnight_green $arrow-white-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-3-next-hover-bg         				: $action-hover $arrow-white-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-3-dot-default-border    				: 2px solid white;
$slick-alt-3-dot-hover-border      				: 2px solid $action-hover;
$slick-alt-3-dot-active-bg-color   				: white;
$slick-alt-3-dot-active-hover-bg-color 		: $action-hover;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : $midnight_green;
$slide-text-bg             : transparent $slider-circle no-repeat scroll 50% 50%;
$slide-text-color          : white;
$slide-text-font           : normal 1.556rem/1.22 $title-font-family;
$slide-text-font-small     : normal 1.333rem/1.45 $title-font-family;
