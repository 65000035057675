// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
	}
}

// SEARCH

// MENU STYLES
#site-header {
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;

		.main-menu-btn {
			background: none;
			background-color:$nav-button-bg-color;
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			margin: -($nav-button-height) 0px 0px 0px;

			.main-menu-btn-icon {
 				left:20px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;

				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}

				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			}
		}

		ul.sm {
			background: none;
			clear:both;
		}

		@include media(880px) {
			clear:right;
			float:right;
			margin-right: 47px;

			@media(min-width: 1151px) {
				margin-right: 58px;
			}
		}


		.menu-block-wrapper ul.menu.sm  {
			border:none;

			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child {
					position:relative;
				}

				@include media(880px) {
					& > a > .sub-arrow {
						display:none;
					}
				}

        a[href="/members-area"],
        a[href="/members-area-homepage"]{
          background-color: $vivid_burgundy;
        }

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					//text-transform: uppercase;



					&:after {
				    content: "";
				    display: block;
				    width: 100%;
				    height: 3px;
				    background: $nav-base-border-color;
						//background-size: 100% 3px !important;
						//background-size: contain !important;
					}

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}
					&.current {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;

						&:after {
					    background: $nav-active-border-color;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
							&:after {
						    background: $nav-hover-border-color;
							}
						}
					}

					&.current.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;

						&:after {
					    background: $nav-active-border-color;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;

							&:after {
						    background: $nav-hover-border-color;
							}
						}
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}
				}
			}

			li {
				a {
					cursor:pointer;
					background-color: transparent;

					.sub-arrow {
						background:$arrow-grey-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}

				ul.menu {
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;

					li {
						border-top:none;

						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							display: flex;

							&:before {
						    content: "";
						    display: block;
						    width: 4px;
						    height: 28px;
						    // margin-right: 15px;
						    background: $nav-sub-border-color;
								position: absolute;
    						top: calc(50% - 14px);
								left: 0;
							}

							&.current {
								background:$nav-sub-bg-color;
								color:$nav-sub-current-text-color;

								.sub-arrow {
									background:$arrow-red-right no-repeat scroll center center transparent;
									background-size:contain;
								}

								&:before {
							    background: $nav-sub-current-border-color;
								}

								&:hover {
									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:contain;
									}

									&:before {
								    background: $nav-sub-active-hover-border-color;
									}
								}
							}

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}

							&.active {
								background-color:$nav-sub-active-bg-color;
								color:$nav-sub-active-text-color;

								&:before {
							    background: $nav-sub-active-border-color;
								}

								.sub-arrow {
									background:$arrow-red-right no-repeat scroll center center transparent;
									background-size:contain;
								}

								&:hover {
									color:$nav-sub-active-hover-text-color;
									background-color:$nav-sub-hover-bg-color;

									&:before {
								    background: $nav-sub-active-hover-border-color;
									}

									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:contain;
									}
								}
							}

							.sub-arrow {
								background:$arrow-grey-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;
							}

							&:hover {
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
						}
						&.expanded {

							& > a.highlighted {
								background-color:$nav-sub-highlight-bg-color;
								//color:$nav-hover-text-color;

								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:contain;
								}

								&:hover {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-sub-hover-text-color;

									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:contain;
									}
								}

								&.current {
									color:$nav-sub-current-expanded-text-color;

									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:contain;
									}

									&:before {
								    background: $nav-sub-current-expanded-border-color;
									}

									&:hover {
										color:$nav-sub-hover-text-color;

										.sub-arrow {
											background:$arrow-red-right no-repeat scroll center center transparent;
											background-size:contain;
										}

										&:before {
									    background: $nav-sub-current-expanded-border-color;
										}
									}
								}

								&.current.active {
									color:$nav-sub-active-text-color;

									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:contain;
									}

									&:before {
								    background: $nav-sub-active-border-color;
									}

									&:hover {
										color:$nav-sub-active-hover-text-color;

										.sub-arrow {
											background:$arrow-red-right no-repeat scroll center center transparent;
											background-size:contain;
										}

										&:before {
									    background: $nav-sub-active-hover-border-color;
										}
									}
								}
							}
						}
					}
				}
			}
		}


////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {

			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: 0; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-16px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:16px; }


			.menu-block-wrapper ul.menu.sm {

				// border: $nav-mob-border;
				// border-top: none;
				box-shadow: $nav-mob-shadow;
				background-color: $nav-mob-1st-bg-color;
				position: absolute;
				right: 0;
				width: calc(100% - 5%);
				max-width: 400px;
				z-index: 1005;

				@media (min-width:$narrow) and (max-width:879px) {
					top: 0;
				}

				& > li {

					&.first.leaf {
						border: none;
						a {
							border: none;
						}
					}
					&.first.first {
						//border: none;
						border-top:$nav-mob-top-border;
					}

					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-mob-1st-text-color;
						padding:$nav-sub-base-padding;
						//text-transform: uppercase;
						//display: flex;
						//flex-direction: column;

						&:before {

							content: "";
							display: block;
							width: 4px;
							height: 28px;
							position: absolute;
							top: calc(50% - 14px);
							left: 0;
						}

						&:after {
							display: none;
						}

						.sub-arrow {
							background-size:50% !important;
						}

						&:hover {
							background-color:$nav-mob-1st-hover-bg-color;
							color:$nav-mob-1st-hover-text-color;
							padding:$nav-sub-base-hover-padding;

							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:50% !important;
							}
						}

						&.current {
							background:$nav-mob-current-bg-color;
							color:$nav-mob-1st-current-text-color;
							padding:$nav-sub-base-hover-padding;
							border-top:$nav-mob-expanded-border;

							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:before {
								background: $nav-mob-1st-active-border-color;
							}

							&:hover {
								background-color:$nav-mob-1st-hover-bg-color;
								color:$nav-mob-1st-hover-text-color;

								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:50%;
								}
								&:before {
									background: $nav-mob-1st-active-hover-border-color;
								}
							}
						}

						&.active {
							background-color: $nav-mob-1st-active-bg-color;
							color: $nav-mob-1st-active-text-color;
							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:before {
								background: $nav-mob-1st-active-border-color;
							}

							&:hover {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:50%;
								}
								&:before {
									background: $nav-mob-1st-active-hover-border-color;
								}
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							padding:$nav-sub-base-hover-padding;
							border-top:$nav-mob-expanded-border;

							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}

							&:hover {
								background-color:$nav-mob-1st-hover-bg-color;
								color:$nav-mob-1st-hover-text-color;

								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
							}
						}

						&.current.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							padding:$nav-sub-base-hover-padding;

							&:before {
								background: $nav-mob-1st-active-hover-border-color;
							}

							&:hover {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							}
						}

						&.active.highlighted {
							color: $nav-mob-1st-active-text-color;

							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
							}
							&:before {
								background: $nav-mob-1st-active-border-color;
							}

							&:hover {
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
								}
								&:before {
									background: $nav-mob-1st-active-hover-border-color;
								}
							}
						}
					}
				}

				li {
					border: none;

					a {
						border-top: $nav-mob-border;
						border-left: none;
						//padding-left: 0.875rem;
						// color: $nav-mob-text-color;
						.sub-arrow {
							background:$arrow-white-right no-repeat scroll center center transparent;
							background-size:50%;
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}

					&.first a {
						border-top:none;
					}


					///////////////////////////////      NEXT LEVELS      ///////////////////////////////

					/* ul BACKGROUNDS */
					&.expanded {

						// 2nd level //
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							border-top: 1px solid $nav-mob-1st-border-color;

							li.first a {
								border-top:none;
							}

							li:not(.first) a {
								border-top: 1px solid $nav-mob-2nd-line-color;
							}

							a {
								border-left: none;
								padding-left: 1.111rem;

								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;
								}

								&:hover {
									background-color: $nav-mob-hover-bg-color;
								}
							}

							li.expanded {

								// 3rd level //
								ul.menu {
									background-color: $nav-mob-3rd-bg-color;
									border-top: 1px solid $nav-mob-2nd-border-color;

									li.first a {
										border-top:none;
									}

									li:not(.first) a {
										border-top: 1px solid $nav-mob-3rd-line-color;
									}

									a {
										border-left: none;
										padding-left: 2.778rem;

										&.active {
											background-color: $nav-mob-active-bg-color;
											color: $nav-mob-active-text-color;
										}
									}
								}
							}
						}
					}

					/* ARROWS + HIGHLIGHT */
					ul.menu {
						box-shadow: none;

						li {
							border-top: none;

							a {
								color: $nav-mob-text-color;
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:50%;
									right:0;
									height: 39px;
									margin-top: -19px;
								}
								&.active {
									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:50%;
									}
								}
								&.current {
									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&:hover {
									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:50%;
									}
								}

								&.active:hover,
								&.current:hover  {
									.sub-arrow {
										background:$arrow-red-right no-repeat scroll center center transparent;
										background-size:50%;
									}
								}
							}

							&.expanded {
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										//background-color:$nav-sub-hover-bg-color;
										color:$nav-mob-active-text-color;
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}

									&:hover {
										background-color:$nav-mob-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}
								}

								////////////**     2nd LEVEL     **////////////
								& > a.highlighted {
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;

									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:50%;
									}

									&:hover {
										background-color:$nav-mob-hover-bg-color;
										color:$nav-hover-text-color;
									}
									&.current.active {
										//background-color:$nav-mob-active-bg-color;
										color:$nav-mob-active-text-color;

										.sub-arrow {
											background:$arrow-red-down no-repeat scroll center center transparent;
											background-size:50%;
										}

										&:hover {
											background-color:$nav-mob-hover-bg-color;
											color:$nav-hover-text-color;

											.sub-arrow {
												background:$arrow-red-down no-repeat scroll center center transparent;
												background-size:50%;
											}
										}
									}

									&.highlighted {
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:50%;
										}

										&:hover {
											.sub-arrow {
												background:$arrow-grey-down no-repeat scroll center center transparent;
												background-size:50%;
											}
										}

										&.current.highlighted {
											.sub-arrow {
												background:$arrow-red-down no-repeat scroll center center transparent;
												background-size:50%;
											}

											&:hover {
												.sub-arrow {
													background:$arrow-red-down no-repeat scroll center center transparent;
													background-size:50%;
												}
											}
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted {
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
								}
							}
						}
					}
				}
			}
		}
	}
}
