.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom: 0; //$vert-space;
		
		.file-icon {
			//float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			display: inline-block;
			.file-extension {
				padding:10px 5px 5px;
        line-height:1;
				text-align:center;
				font: normal 1.111rem/1 $title-font-family;
				letter-spacing: 1px;
				border-radius: 50px 50px 0 0;
			}
		}
		
		.card {	
			margin-bottom: 0; //$vert-space;		
			.file-size {
				font-size:0.778rem;
				color:$secondary;
        border:$secondary-border;
        border-top:none;
				padding-bottom: 5px;
			}
			.file-name {
				overflow:hidden;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
} 
