// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	outline: none;

	transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		// font-family:$card-title-font-family;
		font:$card-title-font;
	}

	h3 {
		display:block;
		font:$card-title-font;
		letter-spacing: 0.5px;

		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
			letter-spacing: 0.5px;
		}
	}
	.card-text {
		padding: $card-text-padding;

		a { word-wrap: break-word; }
	}

	&.card-with-image {
		.card-image {
			position: relative;
			z-index: 3;
		}
		.card-text {
			margin: -30px auto 0;
	    width: calc(100% - #{$horz-space}*2);
			position: relative;
			z-index: 4;
		}
	}
}

article.node-event > .card {
	&.card-with-image {
		.card-text {
			margin: -80px auto 0;
		}
	}
}
// TAGGED CONTENT
.card-title-et {
	h3 {
		font:$card-title-font;
	}
}
.card-announcement-link,
.card-deadline{
  margin-top: 10px;
}
.card-display-top-date {
	background: $card-date-bg-color;
	font:$card-date-font;
	padding: 10px;
	display: inline-block;
	margin-bottom: $vert-space;

	.card-display-top-wrapper {
		border-bottom:$card-date-border;
		border-radius:$card-date-border-radius;
		display: flex;
		align-items: flex-end;
		color:$card-date-text-color;

		.card-top-date {
			font: $card-top-date-font;
			margin-bottom: 3px;
			letter-spacing: 0.5px;
		}
		.card-top-monthyear {
			font: $card-top-monthyear-font;
			width: 35px;
	    margin-left: 3px;
	    margin-bottom: 6px;
			text-transform: uppercase;
			letter-spacing: 0.5px;
		}
	}
}

.card-job-organisation {
	line-height: 1.333rem;
	margin-bottom: 5px;
}

.job-footer {
	//background: $card-date-bg-color;
	//padding: 5px 10px;
	position: absolute;
	bottom: $vert-space;
	//width: calc(100% - #{$vert-space}*2);
	display:inline-block;
	border-bottom:solid 2px $fulvous;

	.card-deadline {
		margin: 0;

		.label, span {
			display: inline-block;
		}
	}
}
.card.announcement{
  .bg-teal {
    background: $vivid_burgundy ;
    height: 100%;
    width: 100%;
    display: inline-block;
    min-height: 20px;
    position: absolute;
    max-height: 60%;
    z-index: 0;
  }
  .card-text {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 2;
    position: relative;
  }
}

.views-row > .node-job > .card > .card-text {
	padding-bottom: $vert-space*3;
}

.palette-default {
	.card-display-top-date,
	.job-footer {
		background: white;
	}
}

.palette-alt-2,
.palette-alt-3,
.palette-highlight {
	.card-display-top-date,
	.job-footer {
		background: $card-date-alt-bg-color;
	}
}

.palette-alt-3 {
	.card-display-top-date {
		.card-display-top-wrapper {
			border-bottom:$card-date-alt-border;
		}
	}
}

@media (min-width: $narrow) and (max-width: $normal) {
	body.one-sidebar {
		main .content-additional {
			.row-max-3,
			.row-max-4 {
			//.row-max-6 {
				.card-display-top-date {
					.card-display-top-wrapper {
						display: block;
					}
				}
			}
		}
	}
	body:not(.one-sidebar) {
		main .content-additional,
		.postscript-first {
			.row-max-3,
			.row-max-4 {
			//.row-max-6
				.card-display-top-date {
					.card-display-top-wrapper {
						display: block;
					}
				}
			}
		}
	}
}

@media (min-width: $normal) and (max-width: 1400px) {
	.row-max-6 {
		.card-display-top-date {
			.card-display-top-wrapper {
				display: block;
			}
		}
	}
}

// /* MORE LINK */
.section-row:not(.view-type-slider),
.side-row:not(.view-type-slider) /* to target sidebar more links separately */
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -3px 10px;
				width:14px;
			}
		}
	}

	.card-more-link-height {
		padding-bottom: 20px;
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

.side-row:not(.view-type-slider) {
	.card-more-link {
		width: 100%;
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	a {
		&:after {
			content: "";
			display: inline-block;
			width: 14px;
			height: 20px;
			margin-left: 5px;
			vertical-align: sub;
			margin-bottom: 2px;
		}
	}
}

// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;

	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;

	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}

.card-date,
.card-location,
.card-display-date,
.card-type,
.card-published-by,
.card-resource-type,
.card-content-type,
.card-date-display-override,
.card-journal,
.card-deadline {
	font-size:0.778em;
	line-height:1.57142857142857;
	letter-spacing: 0.5px;
	margin-bottom: 5px;
}
.card-date {
	margin-bottom: 10px;
}

// .card-type {
// 	font-size:0.778rem;
// 	line-height:1.57142857142857;
// 	letter-spacing: 0.5px;
// }

.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;

	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

// .card-date-display-override {
// 	font-size: 0.875em;
// }
// .card-resource-type,
// .card-content-type {
// 	display:inline-block;
// 	font-weight: 400;
// 	font-size: 0.875em;
// }

.card-email {
	font-weight: 600;
}
.card-job-title {
	font-size:1em;
	line-height:1.375;
}
.card-footer {
	display: block;
	line-height: 1.125em;
}
.card-summary-wrapper {
	.card-summary {
		p {
			margin-bottom: 0.5rem;
		}
	}
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}

//Display box-link card-summary on hover
//@include card-hidden-summary;

// ROW-MAX-1
@media (min-width: 540px) {
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.row-max-1 {
			article:not(.node-teaser-image),
			.custom-link-flexi {
				.card-with-image {
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;

					.card-text {
						width: calc(100% + 20px - 40.29137%);
						margin: 20px 0 0 -20px;
						display: inline-block;
						height: 100% !important;
						border-radius: $card-border-radius;
					}
				}
			}
		}
	}
}
//row-max-1 with sidebars
@media (min-width: 540px) and (max-width:$narrow) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;
						display: flex;
						flex-wrap: wrap;

						.card-text {
							width: calc(100% + 20px - 40.29137%);
							margin: 20px 0 0 -20px;
							display: inline-block;
							height: 100% !important;
							border-radius: $card-border-radius;
						}
					}
				}
			}
		}
	}
}
@media (min-width:$narrow) and (max-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;

						.card-image,
						.card-photo,
						.card-logo {
							float: none;
							position: relative;
						}
					}
				}
			}
		}
	}
}
@media (min-width:$normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 {
				article:not(.node-teaser-image),
				.custom-link-flexi {
					.card-with-image {
						overflow: hidden;
						display: flex;
						flex-wrap: wrap;

						.card-text {
							width: calc(100% + 20px - 40.29137%);
							margin: 20px 0 0 -20px;
							display: inline-block;
							height: 100% !important;
							border-radius: $card-border-radius;
						}
					}
				}
			}
		}
	}
}

//ROW-MAX-2
//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {
	.page-node:not(.sidebar-second) main,
	.postscript-first {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row {
				.card-with-image {
					overflow: hidden;
			    display: flex;
			    flex-wrap: wrap;

					.card-text {
				    width: calc(100% + 20px - 48.82117%);
				    margin: 20px 0 0 -20px;
				    display: inline-block;
						height: 100% !important;
						border-radius: $card-border-radius;
					}
				}
			}
		}
	}
}

.section-row:not(.view-type-slider) {
	.card-image,
	.card-photo,
	.card-logo {
    float: none;
	}
}

.card-announcement-text p {
  margin-bottom: 0;
}
