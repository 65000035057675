.section-row.section-with-banner {
  padding-top: 0;
  position: relative;
  padding-bottom: 0;
  .section-inner {
    max-width: 100%;
    .view-banner {
      position: relative;
      @include outer-container($site-max-width);
      
      .card-banner {
        display: flex;
        align-items: center;
        flex-direction: column;
        
        &:after {
          content: "";
          display: block;
          height: calc(100vw / 7.57);
          width: 101%;
          position: absolute;
          bottom: -5px;
          left: 0;
          // height: 214px;
          width: 100vw;
          background-size: 100vw auto;
          left: calc(50% - 50vw);
          
          @include media (1600px) {
            background-size: 100vw 100%;
            height: 214px;
          }
        }
        
        @include media (540px) {
          min-height: 270px;
          width: 60%;
          flex-direction: row;
        }
        
        @include media ($narrow) {
          min-height: 380px;
          width: 50%;
        }
      
        @include media ($normal) {
          min-height: 570px;
        }
        
        @include media (1600px) {
          width: calc(100% - 860px);
          min-height: 640px;
        }
        
        .card-banner-image {
          background-size: 70%;
          background-position: 30% 0px;
          background-repeat: no-repeat;
          order: 2;
          align-self: flex-end;
          width: 100%;
          padding: 0 20px;
          
          picture {
            position: relative;
            display: block;
          }
          
          @include media (540px) {
            position: absolute;  
            left: 60%;
            // background-size: 270px 270px;        
            // background-position: 0 70px;
            max-width: 340px;
            padding: 0;
            width: 50%;
          }
          
          @include media ($narrow) {
            // background-size: 475px 475px;
            left: 50%;
            max-width: none;
            width: 50%;
            bottom: calc(100vw / 40);
          }
          
          // @include media ($normal) {
          //   background-size: 640px 640px;
          // }
          
          @include media ($wide) {
            width: auto;
          }
          
          @include media (1600px) {
            left: auto;
            right: 0;
            width: auto;
            // background-position: 50% 70px;
            background-size: 640px 640px;
            background-position: 30% 20px;
            bottom: 50px;
          }
        }

        .banner-text-wrapper {
          margin: 0 auto;
          // padding: 40px;
          padding: 40px 40px calc((100vw / 7.57));
          order: 1;
          display: flex;
          justify-content: center;
          flex-flow: column wrap;
          height: 100%;
        
          p:last-child {
            margin-bottom: 0;
          }
        
          .banner-title {
            font: $banner-mobile-title-font;
            margin-bottom: 30px;
          }  
          .card-banner-description {
            font: $banner-mobile-summary-font;
          }
        
          @include media ($narrow) {
            .banner-title {
              font: $banner-narrow-title-font;
            }    
            .card-banner-description {
              font: $banner-narrow-summary-font;
            }
          }
        
          @include media ($normal) {
            .banner-title {
              font: $banner-title-font;
            }    
            .card-banner-description {
              font: $banner-summary-font;
            }
          }
          
          @include media (1600px) {
            padding: 40px 40px 200px;
          }
          
          @include button-link-default();
        }
      }
    }
  }
}

//  BANNER STYLE SPECIFIC STYLES
.section-row.section-with-banner {
  &.banner-style_1 {
    background-color: $banner-bg-color-style-1;
  }
  .style_1 {
    background-color: $banner-bg-color-style-1;
    .banner-text-wrapper  {
      color:$banner-color-style-1;
    }
    .card-banner-image {
      background-image: $banner-bg-image-style-1;
    }
    &:after {
      background: $banner-bg-shape-style-1 no-repeat scroll 0 100% / 100% auto transparent;
    }
  }
  &.banner-style_2 {
    background-color: $banner-bg-color-style-2;
  }
  .style_2 {
    background-color: $banner-bg-color-style-2;
    .banner-text-wrapper  {
      color:$banner-color-style-2;
    }
    .card-banner-image {
      background-image: $banner-bg-image-style-2;
    }
    &:after {
      background: $banner-bg-shape-style-2 no-repeat scroll 0 100% / 100% auto transparent;
    }
  }
  &.banner-style_3 {
    background-color: $banner-bg-color-style-3;
  }
  .style_3 {
    background-color: $banner-bg-color-style-3;
    .banner-text-wrapper  {
      color:$banner-color-style-3;
    }
    .card-banner-image {
      background-image: $banner-bg-image-style-3;
    }
    &:after {
      background: $banner-bg-shape-style-3 no-repeat scroll 0 100% / 100% auto transparent;
    }
  }
  &.banner-style_4 {
    background-color: $banner-bg-color-style-4;
  }
  .style_4 {
    background-color: $banner-bg-color-style-4;
    .banner-text-wrapper  {
      color:$banner-color-style-4;
    }
    .card-banner-image {
      //background-color: $banner-bg-color-style-4;
      background-image: $banner-bg-image-style-4;
    }
    &:after {
      background: $banner-bg-shape-style-4 no-repeat scroll 0 100% / 100% auto transparent;
    }
  }
  &.banner-style_5 {
    background-color: $banner-bg-color-style-5;
  }
  .style_5 {
    background-color: $banner-bg-color-style-5;
    .banner-text-wrapper  {
      color:$banner-color-style-5;
    }
    .card-banner-image {
      background-image: $banner-bg-image-style-5;
    }
    &:after {
      background: $banner-bg-shape-style-5 no-repeat scroll 0 100% / 100% auto transparent;
    }
  }
}