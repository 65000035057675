.crm-container a.button,
.crm-container input.crm-form-submit,
.crm-container .ui-dialog-buttonset .ui-button,
.crm-container input[type=button],
.crm-container .crm-button,
.crm-container span.crm-button {
  text-shadow: none;
  background: none;
  color: $button-link-default-hover-color;
  border: none;
  display: inline-flex;
}
.crm-i-button>.crm-i {
  display: none;
}
.crm-container .crm-button.crm-i-button,
.crm-container .crm-button {
  input.crm-form-submit.button-wrapper,
  input[type=button].button-wrapper,
  input[type="button"].button-wrapper,
  input.crm-form-submit.button-wrapper {
    margin: 0;
    padding: 0.5em 0;
    border-top: $button-border;
    border-bottom: $button-border;
    min-height: auto;
  }
  &:hover {
    input.button-wrapper {
      border-color: $button-link-default-hover-border-color !important;
    }
  }
}

.crm-container input.crm-form-submit,
.crm-container input[type=button],
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75em 1em;
  text-decoration: none !important;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.crm-container a.button,
.crm-container .ui-dialog-buttonset .ui-button,
.crm-container .crm-button,
a.button,
.button a,
.wrapper-region-header .block-menu ul.menu a,
form#mc-embedded-subscribe-form .button {
  display: inline-flex;
  text-decoration: none !important;
  
  .button-wrapper {    
    border-radius: $button-border-radius;
    border-top: $button-border;
    border-bottom: $button-border;
    cursor: pointer;
    display: inline-block;
    font: $button-link-font;
    padding: 0.5em 0;
    text-decoration: none !important;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 1px;	
  }
  
  .button-wrapper {
    background:$button-link-default-bg;
    border-color: $button-link-default-border-color;
    color: $button-link-default-color;
  }
  &:link, &:visited {
    .button-wrapper {
      background:$button-link-default-bg;
      border-color: $button-link-default-border-color;
      color: $button-link-default-color;
    }
  }
  &:before {
    background: $button-link-default-left-icon;
    // background-position: right !important;
  }
  &:after {
    background: $button-link-default-right-icon;
    // background-position: left !important;
  }
  &:hover {
    .button-wrapper {
      background:$button-link-default-hover-bg;
      border-color: $button-link-default-hover-border-color;
      color: $button-link-default-hover-color;
    }
    &:before {
      background: $button-link-default-left-hover-icon;
    }
    &:after {
      background: $button-link-default-right-hover-icon;
    }
  }
  
  &:before,
  &:after {
    content: "";
    display: inline-block;
    height: 48px;
    width: 24px;
    background-repeat: no-repeat !important;
  }
  
  
  &:before{
    background-position: 1px top !important;
  }
  &:after {
    background-position: calc(100% - 1px) top !important;
  }
}

input[type="button"],
input[type="reset"],
input[type="submit"] {  
  &.button-wrapper {
    cursor: pointer;
    display: inline-block;
    font: $button-link-font;
    padding: 0.5em 24px;
    text-decoration: none !important;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 1px;	
  }  
  &.button-wrapper {
    background-image: $button-link-default-left-icon, $button-link-default-border-icon, $button-link-default-border-icon, $button-link-default-right-icon;
    background-position:  1px top, 24px 0px, 24px bottom, calc(100% - 1px) top;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 24px 48px, calc(100% - 47px) 4px, calc(100% - 47px) 4px, 24px 48px;
    min-height: 48px;
    color: $button-link-default-color;
  }  
  &:link, &:visited {
    &.button-wrapper {
      background-image: $button-link-default-left-icon, $button-link-default-border-icon, $button-link-default-border-icon, $button-link-default-right-icon;
      color: $button-link-default-color;
    }
  }
  &:hover {
    &.button-wrapper {
      background-image: $button-link-default-left-hover-icon, $button-link-default-border-hover-icon, $button-link-default-border-hover-icon, $button-link-default-right-hover-icon;
      color: $button-link-default-hover-color;
    }
  }
  
  &#edit-reset {
    &.button-wrapper {
      background-image: $button-link-alt-left-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-right-hover-icon;
      color: $button-link-alt-hover-color;
    }  
    &:link, &:visited {
      &.button-wrapper {
        background-image: $button-link-alt-left-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-right-hover-icon;
        color: $button-link-alt-hover-color;
      }
    }
    &:hover {
      &.button-wrapper {
        background-image: $button-link-default-left-hover-icon, $button-link-default-border-hover-icon, $button-link-default-border-hover-icon, $button-link-default-right-hover-icon;
        color: $button-link-default-hover-color;
      }
    }
  }
}
