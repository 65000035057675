.view.view-members-directory {
  .views-exposed-form {
    background: $platinum;
    margin-bottom: 0;
    padding: 30px;
    label {
      font-style: normal;
      font-size: 1.222em;
      line-height: 140%;
      letter-spacing: 1px;
      color: $outer_space;
      margin-top: 0;
      margin-bottom: 10px;
    }
    .views-exposed-widgets {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      #edit-combine-wrapper {
        padding-right: 40px;
        @media(min-width: 1200px) {
          min-width: 510px;
        }
        input {
          padding: 14px 20px;
          height: 48px;
        }
      }
      #edit-country-wrapper,
      #edit-interest-10-wrapper {
        min-width: 310px;
        padding-right: 40px;
        select {
          padding: 14px 50px 14px 20px;
          height: 48px;
          background-size: 48px;
        }
      }
      .views-exposed-widget-submit {
        align-items: flex-end;
        input {
          margin-bottom: 10px;
          padding: 12px 24px;
        }
      }
    }
  }
  .view-header {
    margin: 30px 0 10px;
  }
  .job-title {
    color: $black_coral;
  }
  .job-location {
    margin-top: 4px;
    font-size: 14px;
    color: $aurometalsaurus;
  }
}
