.credit,
.vid-description {
	background: white $ico-close no-repeat 100% 50%;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 1rem;
	margin: 10px 0;
	padding: 7px 50px 7px 20px;
	position: absolute;
	right: 10px;
	text-align: right;
	top: 0;
	height: 40px;
	border-radius: 40px; 
	color: $primary;
		
	@include media(1600px) {
		right: calc(50% - 790px);
	}
	
	&:empty {
		display: none;
	}
	
	&:hover {
		background: white $ico-close-over no-repeat 100% 50%;
	}

	&.collapsed {
		background: transparent $ico-info no-repeat 50% 50%;
		height: 40px;
		text-align: inherit;
		text-indent: -9999px;
		padding: 0;
		transition: background-color 0.5s linear;
		width: 40px;

		&:hover {
			background: transparent $ico-info-over no-repeat 50% 50%;
		}
	}
}