// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: normal 1.222rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.222rem/1 $title-font-family;
$card-feed-link-font			: normal 1.111rem/1 $title-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : $white_smoke;
$card-date-alt-bg-color        : $light_cyan;
$card-date-border              : 4px solid $turquoise_surf;
$card-date-alt-border          : 4px solid $vivid_burgundy;
$card-date-border-radius       : 0;
$card-date-text-color          : $primary;
$card-date-font                : 0.778rem/1 $base-font-family;
$card-top-date-font            : 2.778rem/1 $title-font-family;
$card-top-monthyear-font       : 0.778rem/1.286 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $turquoise_surf; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
// $card-date-hover-text-color    : $primary;
// $card-date-hover-bg-color      : $white_smoke; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $primary;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $light_cyan;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $action-default;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : blue;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $primary;
$card-dark-action-default      : $action-default;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : red;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $primary;
$card-highlight-action-default : $primary;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $secondary;
$card-highlight-subtitle-color : $secondary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $light_cyan;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-black-right no-repeat 100% 100% / 14px 20px;
$card-more-link-default-hover-bg    : $light_cyan;
$card-more-link-default-hover-color : $action-hover;
$card-more-link-default-hover-icon  : $arrow-blue-right no-repeat 100% 100% / 14px 20px;

$card-more-link-alt-bg              : white;
$card-more-link-alt-color           : $primary;
$card-more-link-alt-icon            : $arrow-black-right no-repeat 100% 100% / 14px 20px;
$card-more-link-alt-hover-bg        : white;
$card-more-link-alt-hover-color     : $action-hover;
$card-more-link-alt-hover-icon      : $arrow-blue-right no-repeat 100% 100% / 14px 20px;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : transparent; // background for the entire link
$card-feed-link-default-color       : $action-default;
$card-feed-link-default-icon        : transparent $arrow-red-right no-repeat scroll 50% 50% / 14px 20px; //background for the default :after component
$card-feed-link-default-hover-bg    : transparent;
$card-feed-link-default-hover-color : $action-hover;
$card-feed-link-default-hover-icon  : transparent $arrow-blue-right no-repeat scroll 50% 50% / 14px 20px;

$card-feed-link-alt-bg              : transparent; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : transparent $arrow-white-right no-repeat scroll 50% 50% / 14px 20px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : transparent;
$card-feed-link-alt-hover-color     : $skibeloff;
$card-feed-link-alt-hover-icon      : transparent $arrow-dark-right no-repeat scroll 50% 50% / 14px 20px;

$card-feed-link-alt2-bg              : transparent; // background for the entire link
$card-feed-link-alt2-color           : $white;
$card-feed-link-alt2-icon            : transparent $arrow-white-right no-repeat scroll 50% 50% / 14px 20px; //background for the alternative :after component
$card-feed-link-alt2-hover-bg        : transparent;
$card-feed-link-alt2-hover-color     : $action-hover;
$card-feed-link-alt2-hover-icon      : transparent $arrow-blue-right no-repeat scroll 50% 50% / 14px 20px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : transparent;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : $action-default;
$button-link-default-icon               : none;
$button-link-default-left-icon          : $btn-outline-red-left;
$button-link-default-right-icon         : $btn-outline-red-right;
$button-link-default-border-icon        : url(./../images/btn-outline-red.png);//$btn-outline-red;
$button-link-default-hover-bg           : transparent;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : $action-hover;
$button-link-default-hover-icon         : none;
$button-link-default-left-hover-icon    : $btn-outline-blue-left;
$button-link-default-right-hover-icon   : $btn-outline-blue-right;
$button-link-default-border-hover-icon  : url(./../images/btn-outline-blue.png);

$button-link-alt-bg                     : transparent;
$button-link-alt-border-color           : white;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-left-icon              : $btn-outline-white-left;
$button-link-alt-right-icon             : $btn-outline-white-right;
$button-link-alt-border-icon            : url(./../images/btn-outline-white.png);
$button-link-alt-hover-bg               : transparent;
$button-link-alt-hover-border-color     : $skibeloff;
$button-link-alt-hover-color            : $skibeloff;
$button-link-alt-hover-icon             : none;
$button-link-alt-left-hover-icon        : $btn-outline-dark-left;
$button-link-alt-right-hover-icon       : $btn-outline-dark-right;
$button-link-alt-border-hover-icon      : url(./../images/btn-outline-dark.png);

$button-link-alt2-bg                     : transparent;
$button-link-alt2-border-color           : white;
$button-link-alt2-color                  : white;
$button-link-alt2-icon                   : none;
$button-link-alt2-left-icon              : $btn-outline-white-left;
$button-link-alt2-right-icon             : $btn-outline-white-right;
$button-link-alt2-border-icon            : url(./../images/btn-outline-white.png);
$button-link-alt2-hover-bg               : transparent;
$button-link-alt2-hover-border-color     : $action-hover;
$button-link-alt2-hover-color            : $action-hover;
$button-link-alt2-hover-icon             : none;
$button-link-alt2-left-hover-icon        : $btn-outline-blue-left;
$button-link-alt2-right-hover-icon       : $btn-outline-blue-right;
$button-link-alt2-border-hover-icon      : url(./../images/btn-outline-blue.png);
