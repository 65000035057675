.page-user-login,
.page-user.not-logged-in {
  // #page-title {
  //   background: $white;
  //   padding: 30px 30px 0 30px;
  //   margin-bottom: -30px;
	// 	@include media($narrow) {
	// 		padding: 30px 20px 0 20px;
	// 	}
  // }
		
	.content-main.login-content-main {
		
		@include media($narrow) {
			display: flex;
		}
		
		.join-already-member {
			margin-top: 0; // $vert-space*2;
			
			padding-bottom: $vert-space*2; 
			border-bottom: 1px solid $white_smoke;
			
			@include media($narrow) {
				padding-bottom: 0;
				border-bottom: 0;
				
				width: 50%;
				padding-right: 50px;
				margin-right: 0px;
				border-right: 1px solid $white_smoke;
			}
			
			@include media($normal) {
				padding-right: 100px;
			}
			
			#user-login {
				
				.form-item.form-type-password, .form-actions.form-type-password {
					max-width: 100%;
				}
				
				input {
					// width: 100%;
					margin-bottom: 20px;
					max-width: 100%;
				}
				.req-pass {
					font-family: $title-font-family;
					font-size: 1.111rem;
				}

				.form-item {
					margin-top: 0;
					
					// label {
					// 	color: $dark_purple;
					// 	font-size: 1.111rem;
					// 	font-family: $title-font-family;
					// 	font-weight: normal;
					// 	line-height: 1.444;
					// 	padding: 0.333em;
					// 	padding-left: 0;
					// 	margin-right: 1.667em;
					// }
					&.form-item-pass {
						justify-content: flex-end;
					}
				}

				@include media($normal) {
					#edit-actions {
						display: flex;
						flex-direction: row-reverse;
						align-items: center;
						margin: $vert-space*2 0 $vert-space*3;

						input {
							width: auto;
							margin: 0 0 0 $horz-space;
						}
					}
				}
			}
			
			.join-summary-text {				
				margin-top: $vert-space*1.5;
			}
		}
		
		.join-buttons {			
			margin-top: $vert-space*2.5;

			@include media($narrow) {
				margin-top: 0; // $vert-space*2;
				width: 50%;
				padding-left: 50px;
			}
			
			@include media($normal) {
				padding-left: 100px;
			}
			
			.join-member {
				margin-bottom: $vert-space*2;
			}
		}
		
		.join-summary {			
			p.larger-text {
				font-size: inherit;
				line-height: inherit;
			}
			
			// a.button {
			// 	border: 2px solid;
			// 	&:hover {
			// 		background: $dingy_dungeon;
			// 		color: $white;
			// 		border: 2px solid;
			// 	}
			// }
		}
	}
	
  // .postscript-first,
  // .tabs.outer-wrapper {
  //   display: none;
  // }
}