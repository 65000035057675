// /* TAGLINES PALETTE */
$tagline-font                  : 1.778rem/1.4 $title-font-family;
$tagline-font-smaller          : 1.778rem/1.4 $title-font-family;

$tagline-bg-default            : transparent;
$tagline-color-default         : $primary;
$tagline-accent-default        : transparent;

$tagline-bg-alt-1              : transparent;
$tagline-color-alt-1           : $primary;
$tagline-accent-alt-1          : transparent;

$tagline-bg-alt-2              : transparent;
$tagline-color-alt-2           : $primary;
$tagline-accent-alt-2          : transparent;

$tagline-bg-alt-3              : transparent;
$tagline-color-alt-3           : $primary;
$tagline-accent-alt-3          : transparent;

$tagline-bg-highlight          : transparent;
$tagline-color-highlight       : white;
$tagline-accent-highlight      : transparent; 