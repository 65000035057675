fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	margin-bottom: $vert-space/4;
	font-family:$title-font-family;
	font-size: 1.222rem;
	font-weight: normal;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#crm-container.crm-public input[type="text"],
#crm-container.crm-public input[type="password"],
#crm-container.crm-public input[type="email"],
#crm-container.crm-public select,
#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-alt-border-radius;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777rem;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space;
	transition: border-color;
	width: 100%;
	height: 38px;
	max-width: 370px;
  background: none;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: none;
		box-shadow: none;
		outline: none;
	}
}

textarea {
	max-width: none;
}

.views-exposed-form,
#mc_embed_signup {
  #{$all-text-inputs},
  select[multiple=multiple],
  textarea {
    background-color: $light_cyan;
    border: none;
    border-radius: $form-border-radius;
		max-width: none;
  }
}


textarea {
	resize: vertical;
}

input::placeholder {
	font-size: 0.777rem !important;
	color:$aurometalsaurus !important;
	padding: 0 !important;
}
input[type="search"] {
	@include appearance(none);
}
input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}
input[type="file"] {
	margin-bottom: $vert-space/2;
	width: 100%;
	max-width: 370px;
}
.form-managed-file .form-file, .form-managed-file .form-submit {
	margin-bottom: 10px;
}

.select2-container .select2-choice,
.crm-container.crm-public .select2-container .select2-choice,
#crm-container.crm-public select,
select {
	background: white $arrow-filter no-repeat 100% 50% / 38px 38px;
	width: auto;
	border: $form-border;
	// border-right: none;
	border-radius: $form-alt1-border-radius;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 0.777rem;
	margin-bottom: $vert-space/2;
	max-width: 370px;
	padding: $vert-space/3 $horz-space*3 $vert-space/3 $horz-space;
	transition: border-color;
	height: 38px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.views-exposed-form,
#mc_embed_signup {
	select {
		background: $light-cyan $arrow-filter no-repeat 100% 50% / 38px 38px;
		border: none;
		border-radius: $form-border-radius;
		max-width: 100%;
	}
}

select:focus {
    outline: none;
}


// CHECKBOX
// .form-checkboxes,
// .form-type-checkbox {
	input[type=checkbox] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	// input[type=checkbox] + label.option
	input[type=checkbox] + label {
		display:inline-block;
		line-height:1.222rem;
		background-repeat:no-repeat;
		font-family:$base-font-family;
		font-weight: normal;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		font-size: 0.777rem;
		&:hover {
			color: $vivid_burgundy;
		}
	}
	// input[type=checkbox]:checked + label.option
	input[type=checkbox]:checked + label {
		background: $checkbox-active no-repeat scroll 0 0 / 18px 18px transparent;
		&:hover {
			background: $checkbox-active no-repeat scroll 0 0 / 18px 18px transparent;
		}
	}
	// input[type=checkbox] + label.option
	input[type=checkbox] + label {
		background: $checkbox-default no-repeat scroll 0 0 / 18px 18px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&:hover {
			background: $checkbox-hover no-repeat scroll 0 0 / 18px 18px transparent;

		}
	}
// }
.form-checkboxes,
.form-type-checkbox {
	.description {
		margin-left: 1.75em;
	}
}

// RADIO
// .form-radio,
// .form-type-radio {
	input[type=radio] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}
	// input[type=radio] + label.option
	input[type=radio] + label {
		display:inline-block;
		line-height:1.222rem;
		background-repeat:no-repeat;
		font-weight: normal;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
		font-size: 0.777rem;
		font-family:$base-font-family;
		&:hover {
			color: $vivid_burgundy;
		}
	}
	// input[type=radio]:checked + label.option
	input[type=radio]:checked + label {
		background: $radio-active no-repeat scroll 0 0 / 18px 18px transparent;
		&:hover {
			background: $radio-active no-repeat scroll 0 0 / 18px 18px transparent;
		}
	}
	// label.option
	input[type=radio] + label {
		background: $radio-default no-repeat scroll 0 0 / 18px 18px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&:hover {
			background: $radio-hover no-repeat scroll 0 0 / 18px 18px transparent;
		}
	}
// }

.form-radio,
.form-type-radio {
	.description {
		margin-left: 1.75em;
	}
}

// WEBFORM
.webform-progressbar {
	width: 100%;

	.webform-progressbar-outer {
		border: none;
		height: auto;
    display: flex;
		margin: 0 0 2em;
		background-color: transparent;
		flex-wrap: wrap;

		.webform-progressbar-inner {
			display: none;
		}
		.webform-progressbar-page {
			position: relative;
			width: auto;
			height: 50px;
			margin: 0 0 20px;
			border-radius: 0;
			border: none;
			left: auto !important;
			background-color: transparent;
			display: inline-flex;
			&:after {
		    content: "";
		    display: inline-block;
		    height: 50px;
		    width: 50px;
				margin: 0 6px;
				background: $webform-arrow no-repeat 50% 50% / 50px 50px transparent;
		  }

			.webform-progressbar-page-label {
				display: none !important;
			}
			.webform-progressbar-page-number {
				display: inline-flex;
				position: relative;
				color:$gainsboro;
				font-family:$title-font-family;
				// font-weight: 900;
				font-size: 30px;
				width: 50px;
				height: 50px;
				margin: auto;
				border: none;
				left: auto !important;
				justify-content: center;
    		align-items: center;
				background: $webform-circle-light no-repeat 50% 50% / 50px 50px transparent;
			}

			&.current {
				.webform-progressbar-page-number {
					color:$turquoise_surf;
					background: $webform-circle-blue no-repeat 50% 50% / 50px 50px transparent;
				}
			}
			&.completed {
				.webform-progressbar-page-number {
					color:$aurometalsaurus;
					background: $webform-circle-dark no-repeat 50% 50% / 50px 50px transparent;
				}
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}

.webform-client-form,
.block-webform {

	& > div > .form-item, .form-actions {
    margin-bottom: 1.667rem;
	}

	.form-item.webform-component-markup > h2 {
		font-size: 1.889rem;
	}



	.webform-datepicker {
		display: flex;
		margin: 0;
		flex-flow: row wrap;

		.form-item, .form-actions {
    	margin: 0 10px 0 0;
		}
		input.webform-calendar {
	    display: inline;
	    height: 21px;
		}
		select {
			min-width: 115px;
		}
	}

	.webform-component-time .webform-container-inline {
		display: flex;
		align-items: center;
		margin-top: 0;
		flex-flow: row wrap;

		.form-item-submitted-time-hour {
			margin: 0 10px 0 0;
		}
		.form-item-submitted-time-minute {
			margin: 0 20px 0 10px;
		}
		select {
			min-width: 115px;
		}
	}

	.form-actions {
		input {
			margin-right: $horz-space;

			&.webform-previous {
				background-image: $button-link-alt-left-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-right-hover-icon;
				color: $button-link-alt-hover-color;
			}
			&:link, &:visited {
				&.webform-previous {
					background-image: $button-link-alt-left-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-border-hover-icon, $button-link-alt-right-hover-icon;
					color: $button-link-alt-hover-color;
				}
			}
			&:hover {
				&.webform-previous {
					background-image: $button-link-default-left-hover-icon, $button-link-default-border-hover-icon, $button-link-default-border-hover-icon, $button-link-default-right-hover-icon;
					color: $button-link-default-hover-color;
				}
			}
		}
	}

	&.preview {
		.webform-page {
			display: none;
		}
	}

	fieldset.webform-component-fieldset {
		padding: 0 30px;
    border: 2px solid $gainsboro;
    //max-width: 370px;
    width: 100%;
    height: auto;
    margin: 0 0 1.667rem;

    @media (min-width: $narrow) {
      max-width: 80%;
    }
    @media (min-width: $normal) {
      max-width: 60%;
    }

		legend {
			color:$primary;
			font-family:$title-font-family;
			margin-top:0;
			text-transform:none;
			font-size: 1.444rem;
	    background-color: white;
	    padding: 0 10px;
	    margin-left: -10px;
	    margin-bottom: 0;
		}
	}
}

aside {
	.webform-client-form, .block-webform {
		.webform-datepicker {
			.webform-component-time .webform-container-inline {
				flex-flow: column wrap;
			}
		}
	}
}
.webform-confirmation + .links {
	a {
    cursor: pointer;
    display: inline-block;
    font: $button-link-font;
    padding: 0.75em 24px;
    text-decoration: none !important;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    letter-spacing: 1px;

    background-image: $button-link-default-left-icon, $button-link-default-border-icon, $button-link-default-border-icon, $button-link-default-right-icon;
    background-position:  left top, 24px 0px, 24px bottom, right top;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 24px 48px, calc(100% - 47px) 4px, calc(100% - 47px) 4px, 24px 48px;
    min-height: 48px;
    color: $button-link-default-color;

	  &:link, &:visited {
      background-image: $button-link-default-left-icon, $button-link-default-border-icon, $button-link-default-border-icon, $button-link-default-right-icon;
      color: $button-link-default-color;
	  }
	  &:hover {
      background-image: $button-link-default-left-hover-icon, $button-link-default-border-hover-icon, $button-link-default-border-hover-icon, $button-link-default-right-hover-icon;
      color: $button-link-default-hover-color;
	  }
	}
}


.form-item, .form-actions {
  margin-top: 1em;
  margin-bottom: 1.667rem;

	&.form-type-password {
		margin-bottom: 0;
		max-width: 370px;
	}

	.description {
		font-family: $base-font-family;
		font-size: 0.777rem;
		font-weight: normal;
    width: 100% !important;
		margin-bottom: 5px;

		&.password-suggestions {
			padding: 10px 10px !important;
			border: $form-border !important;
			border-radius: $form-alt-border-radius;
			max-width: 370px;
		}
	}
}

// TEXT FIELDS
.civicrm-enabled.form-textarea,
.form-textarea-wrapper textarea {
    min-height: 120px;
}

// LOGIN
.not-logged-in.page-user {
	.tabs.outer-wrapper {
		display: none;
	}
}


// CREATE
#edit-field-by-creating-an-account-wit {
	margin-top: 2rem;

	label {
		font-size: 0.889rem;
		font-weight: normal;
	}
}

.crm-container {
	#editrow-prefix_id {
		width: 100%;
		max-width: 180px;
	}
	.crm-form-select {
		max-width: 370px !important;
		width: 100% !important;
	  min-width: auto !important;
	}
	.crm-section .label {
    text-align: left !important;
	}
	.select2-container .select2-choice {
		border: $form-border;
		border-radius: $form-alt-border-radius;
		height: 38px;
		box-shadow: none;

		& >.select2-chosen {
			margin-right: 35px;
			color: $primary;
		}

		.select2-arrow {
			width: 30px;
			display: none;

			&:before {
		    top: 3px;
			}
		}
	}

	&.crm-public .crm-section .content {
		margin: 0 !important;
		clear: both;
	}

	fieldset {
		border: none;
	}

	.crm-section .label {
		width: auto;
	}
}

#crm-container.crm-public input[type=radio] + label,
#crm-container.crm-public input[type=checkbox] + label {
	font-size: 0.777rem;
	&:hover {
			color: $vivid_burgundy;
		span {
			color: $vivid_burgundy;
		}
	}
}
.crm-container.crm-public .select2-container * {
  box-sizing: inherit;
}

.select2-drop.select2-drop-active.crm-container {
	border: $form-border;
	max-width: 385px;

	.select2-results {
		color: $primary;
		&.select2-highlighted {
			background: $light_cyan;
		}
	}
}

select.webform-conditional-disabled {
  background: none;
  border-radius: 0px;
	border-color: $blue_shapphire;
	color: $blue_shapphire;
	padding: $vert-space/2 $horz-space;
	font-size: 1rem;
  height: auto;
}

form ul.token-input-list {
  border-radius: $form-alt-border-radius;
  border: $form-border;
	max-width: 370px;
	width: 100%;
  padding: $vert-space/3 $horz-space/2;
	background-color: white;
	box-sizing: border-box;

	font-family: $base-font-family;
	font-size: 0.777rem;
	margin-bottom: $vert-space/2;

	transition: border-color;
	width: 100%;
	height: 38px;
	max-width: 370px;

	li.token-input-token {
		border-radius: $form-alt-border-radius/2;
		background-color: $light_cyan;
	}

	input[type="text"] {
		height: auto;
		margin: 0;
	}
}

.webform-component--civicrm-1-contact-1-contact-image-URL {
	input[type="file"] {
		width: auto;
	}
	input[type="submit"].button-wrapper {
		display: block !important;
    clear: both;
    margin: 5px 0 10px;
	}
}

#user-register-form > div,
#user-profile-form > div {

	div#edit-civicrm-profile-register {
		margin: 0 0 0.667rem;

		#editrow-last_name {
			margin-bottom: 0;
		}
	}

	.password-strength,
	div.password-confirm {
		width: auto;
		font-size: 0.778rem;
		margin-top: 0;
	}
}
.crm-container div.form-item {
	margin: 1em 0 1.667rem;
	padding: 0;
}
#edit-timezone {
	display: none;
}
.confirm-parent, .password-parent {
	width: auto;
}

.crm-container {
	fieldset {
		legend {
			display: none;
		}
		.crm-section {
			.label {
				float: none;
				text-align: left;
				margin-bottom: 5px;
				width: 100%;
			}
			label {
		    font-size: 1.222rem;
		    font-weight: normal !important;
			}
			.edit-value {
				margin: 0
			}
			input {
				background-color: white;
		    border: 1px solid #D7D9DC;
		    border-radius: 4px;
		    box-sizing: border-box;
		    font-family: "Arial",sans-serif;
		    font-size: 0.777rem;
		    margin-bottom: 0;
		    padding: 6.66667px 20px;
		    transition: border-color;
		    width: 100%;
		    height: 38px;
		    max-width: 370px;
    		box-shadow: none;
			}
		}
	}
}

// CAPTCHA
.captcha.form-wrapper {
	padding-left: 0;

	legend,
	.fieldset-wrapper .fieldset-description {
		display: none;
	}
}
