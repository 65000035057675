// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:1.25rem;
			background-size:contain;
			@include media($narrow) {
				left:0;
			}
		}
		&.slick-next {
			right:1.25rem;
			background-size:contain;
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 50px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

main .side-row {
	.slick-controls, .slick-nav {
		.slick-dots li {
			@include media($narrow) {
				display: none !important;
			}
			@include media(1100px) {
				display: inline-block !important;
			}
		}			
	}
} 

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-prev, .slick-next {
    height: 50px;
    width: 50px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:35%;
		}
		@include media($normal) {
			width:40%;
		}
		@include media($wide) {
			width:25%;
		}
	}
}

body.sidebar-second main {
	.section-row {
		.slick-controls {
			@include media($narrow) {
				width:55%;
			}
			@include media($normal) {
				width:50%;
			}
			@include media($wide) {
				width:40%;
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
	}
}

// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 25px;
	}
	.slick-dots {
		bottom:14px;
	}	
}

// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					
					.card-description-inner {
						padding-bottom: 70px;
						
						@include media($narrow) {
							padding-bottom: 0;
						}
						
						.card-slide-description {
							position:relative;
							// background:$slide-text-bg-color;
							padding:30px 20px;
							margin:0;
							color:$slide-text-color;
							min-height:66px;
							font:$slide-text-font;
							p {
								font:$slide-text-font;
								margin: 0;
							}

							@include media($narrow) {
								width: 380px;
								height: 380px;
								background:$slide-text-bg;
								position:absolute;
								text-align: center;
								letter-spacing: 1px;
								padding: 130px 100px;
								top: calc(50% - 250px);
								left: 60px;
							}

							@include media($normal) {
								width: 500px;
								height: 500px;
								background:$slide-text-bg;
								padding: 130px 100px;
								top: calc(50% - 250px);
							}
						}
					
						.card-slide-link-button {
							padding: 0 20px 20px;
					    text-align: center;
							
							@include button-link-alt2();
						}
					}
						
					.card-slide-content-title {
						display: none !important;
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:20px;
		width:100%;
		left:0;
		
		@include media($narrow) {
			top: calc(50% - 38px);
	    width: 540px;
	    left: 40px;
	    height: 50px;
		}
		
		button.slick-arrow {
			&.slick-prev,
			&.slick-next {
				border-radius: 50%;
			}
		}
		
		.slick-dots {
	    bottom: -120px;
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front {
// .not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		// @media (min-width:1600px)
		@include media($narrow) {
			max-height: 620px;
			overflow: hidden;

			.view-slider .view-content .slick-list .slick-track .views-row {
				margin: 0;

				.card-image {
					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							left:calc(50% - 480px);
							width: auto;

							@include media($normal) {
								left:calc(50% - 800px);
								width: auto;
							}							

							@media (min-width:1600px) {
								left:0;
								width: 100%;
							}
						}
					}
				}
				
				.card-slide-description {
					background-color:$slide-text-bg-color;
					padding:60px 50px 140px;
					margin:0;
					color:$slide-text-color;
					min-height:66px;
					position:absolute;
					bottom: auto;
  				top: calc(50% - 190px);
					border-radius: 50%;
					display: flex;
					align-items: center;
					
					font:$slide-text-font-small;
					p {
						font:$slide-text-font-small;
						margin: 0;
					}
					
					@include media($normal) {
						bottom: auto;
    				top: calc(50% - 250px);
						padding:130px 100px 200px;
						border-radius: 0;
						background-color: transparent;
						
						font:$slide-text-font;
						p {
							font:$slide-text-font;
							margin: 0;
						}
					}
					
					@include media(1600px) {
    				top: calc(310px - 250px);
						left: calc(50% - 800px + 65px);
					}
				}
				
				.card-slide-link-button {
					position: absolute;
			    display: block;
					top: 280px;
			    left: 100px;
			    width: 300px;
					padding: 0;
					margin: 0;
					
					@include media($normal) {
				    top: 365px;
				    left: 160px;
					}
					
					@include media(1600px) {
						left: calc(50% - 630px);
					}
				}
			}
		}
	
		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}

		.view-slider .view-footer {
			@include slick-controls-alt-2();
			
			@include media($narrow) {
				@include slick-controls-alt-3();
			}
			
			.slick-nav button.slick-arrow {
				&.slick-next,
				&.slick-next:hover {
					background-position-x: calc(50% + 1px) !important;
					
					@include media($narrow) {
						background-size: 20px 20px !important;
						background-position-x: calc(50% + 2px) !important;
					}
				}
				
				&.slick-prev,
				&.slick-prev:hover {
					background-position-x: calc(50% - 1px) !important;
					
					@include media($narrow) {
						background-size: 20px 20px !important;
						background-position-x: calc(50% - 2px) !important;
					}
				}
			}
			
			@include media($narrow) {
				top: calc(50% - 30px);
		    width: 420px;
		    left: 40px;
			}
			@include media($normal) {
				top: calc(50% - 25px);
				width: 540px;
			}
			@include media(1600px) {
				top: calc(310px - 25px);
    		left: calc(50% - 800px + 45px);
			}
		}
	}
}

.front,
.not-front {
	& > .page > section:not(.content-hero) {
		.section-row.section-with-slider .section-inner {
			max-width: $site-max-width;
		}
	}
}

// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%; 
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;				
			}
		}
	}
}

.one-sidebar .sidebar {
	.side-row.section-with-slider {
		
		max-width: calc(100% - 30px);
		
		@include media($narrow) {
			max-width: 100%;
		}
		
		.view-slider {
			position:relative;
			.view-content {
				margin:0;
				.slick-list {
					overflow:visible;
					.slick-track {
						background-color:$slide-text-bg-color;
			
						.views-row {
							margin: 0;
		
							.card-slide-image {
								position:relative;
								img {
									max-width:none;
									position:relative;
									width: 100%;
								}
							}
							
							.card-description-inner {
								padding-bottom: 70px;
								
								.card-slide-description {
									position:relative;
									padding:30px 20px;
									margin:0;
									color:$slide-text-color;
									min-height:66px;
									font:$slide-text-font;
									left: 0;
									height: auto;
									p {
										font:$slide-text-font;
										margin: 0;
									}
								}
							
								.card-slide-link-button {
									padding: 0 20px 20px;
							    text-align: center;
									
									@include button-link-alt2();
								}
							}
								
							.card-slide-content-title {
								display: none !important;
							}
						}
					}
				}
				
				.card-edit-node a {
					@include button-states($button-bg, $button-text, $button-border-color);
					font-size: 0.875rem;
					font-weight: bold;
					padding: 5px 15px;
					position: absolute;
					left: 0;
					text-transform: uppercase;
					top: 0;
				}
			}
			
			// SLIDER NAVIGATION
			.view-footer {
				position:absolute;
				bottom:20px;
				width:100%;
				left:0;
				top: auto;
				
				@include media($narrow) {
					padding: 0 1.25rem;
				}
				
				button.slick-arrow {
					&.slick-prev,
					&.slick-next {
						border-radius: 50%;
					}
				}
				
				@include slick-controls-alt-2();
				
				.slick-nav {
					position: relative;
				}
				
				.slick-dots {
			    bottom: -120px;
				}
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
    margin-top: $vert-space;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}



