//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);
@import url(../fonts/fonts.css);

$base-font-family      		: 'Arial', sans-serif;
$title-font-family    		: 'Swiss721W01-BlackConden', serif;
$subtitle-font-family 	 	: 'Swiss721W01-BlackConden',serif;

$title-alt-font-family    		: 'Swiss_721 W01 Condensed', serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: normal $base-font-size/1 $title-font-family;

/* FORMS */
$button-font 							: normal $base-font-size/1 $title-font-family;
