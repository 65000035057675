main {
	background-color:$main-page-bg;

	#content {
		padding:0 $horz-space/2;
		@media screen and (min-width:$narrow) {
			padding:0 $horz-space;
		}
	}

	#content-custom-title {
		width: 100%;
		padding:0 $horz-space/2;
		@media screen and (min-width:$narrow) {
			padding:0 $horz-space;
		}
	}
}
.postscript-first {
	background-color:$main-page-bg;
}

.front main {display: none}

.outer-wrapper {
	@include outer-container($site-max-width);
}

hr {
	border:none;
	border-bottom:$primary-border;
	margin: 0 0 $vert-space;
}
p + hr,
ul + hr,
ol + hr,
address + hr,
pre + hr,
blockquote + hr {
	margin-top: $vert-space;
}

.caption {
	display: block;
	text-align: left;
	font-size: 0.875em;

	p {
		line-height: 1.42857142857143;
		margin: 5px 0 1em;

		&:last-child {margin-bottom: 0;}
	}
}

.login-wrapper {
  display: flex;
}

.log-message {
  padding: 10px;
  border: solid 1px #E98300;
  background: #FFEED8;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 4px;
}

.role-administrator,
.role-member,
.role-editor {
  .view-member-redirect {
    display: none;
  }
}
