.node-type-job {
  
  .jobs-header {
    padding: $vert-space*2 $horz-space*2 $vert-space;
    margin: $vert-space 0 $vert-space*2;
    background-color: $light_cyan;
    
    .node-header-details {
      display: flex;
      flex-wrap: wrap;
    
      .job-header-detail {
        width: 100%;
        
        @media (min-width:580px) {
          width: 50%;
        }
        
        div.label {
          font: $button-font;          
          margin-bottom: $vert-space/4;
        }
        div:not(.label) {
          display: inline-block;
          font-size: 1rem;
          margin-bottom: $vert-space;
        }
      }  
    }
  }
      
  
  aside {
    a.button.button-apply-job {
      width: 100%;
      
      .button-wrapper {
        width: calc(100% - 50px);
        text-align: center;
      }
    }
  }
}

.view-jobs .view-filters {
  overflow: hidden;
    
  .views-exposed-form {
    float: right;
    padding: 7px 4px 7px 12px;
    margin-bottom: $vert-space*1.5;    
    border-radius: 30px;
    background: transparent;
    
    .views-exposed-widgets {
      margin: 0;
      width: 190px;
      
      .views-exposed-widget-submit {
        display: none;
      }
      
      .views-exposed-widget {
        width: 100% !important;
        padding: 0;
        
        label {
          display: inline-block;
          font-size: 0.889rem;
          margin: 0 5px 0 0;
        }
        
        select#edit-sort-by {
          width: 130px;
          display: inline-block;
          height: 32px;
          background-size: 32px;
          margin: 0;
          padding: 6.66667px 40px 6.66667px 15px;
          background-color: white;
        }
      }
    }
  }
}

.palette-default  {
  .view-jobs .view-filters .views-exposed-form .views-exposed-widgets .views-exposed-widget {
    select#edit-sort-by {
      background-color:$light_cyan;
    }
  }      
}