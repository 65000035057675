$button-bg           : #81D2C7 !default;
$button-text-color   : white !default;
$button-border-color : transparent !default;

@mixin button-states($button-bg, $button-text, $button-border-color) {
	background: $button-bg;
	border-color:$button-border-color;
	color: $button-text;
	display: inline-block;
	transition: all 0.25s ease-in-out;
	
	&:link, &:visited {
		background: $button-bg;
		border-color:$button-border-color;
		color: $button-text;
	}
	
	&:hover {
		background: darken($button-bg,10%);
		border-color: darken($button-border-color,10%);
		color: $button-text;
	}
}


// /* BUTTON LINKS */
@mixin button-link-default() {
	a.button,
	.button a,
	form#mc-embedded-subscribe-form .button {
		.button-wrapper {
			background:$button-link-default-bg;
			border-color: $button-link-default-border-color;
			color: $button-link-default-color;
		}
		&:link, &:visited {
			.button-wrapper {
				background:$button-link-default-bg;
				border-color: $button-link-default-border-color;
				color: $button-link-default-color;
			}
		}
		&:before {
			background: $button-link-default-left-icon;
		}
		&:after {
			background: $button-link-default-right-icon;
		}
		&:hover {
			.button-wrapper {
				background:$button-link-default-hover-bg;
				border-color: $button-link-default-hover-border-color;
				color: $button-link-default-hover-color;
			}
			&:before {
				background: $button-link-default-left-hover-icon;
			}
			&:after {
				background: $button-link-default-right-hover-icon;
			}
		}
	}
}

@mixin button-link-alt() {
	a.button,
	.button a,
	form#mc-embedded-subscribe-form .button {
		.button-wrapper {
			background:$button-link-alt-bg;
			border-color: $button-link-alt-border-color;
			color: $button-link-alt-color;
		}
		&:link, &:visited {
			.button-wrapper {
				background:$button-link-alt-bg;
				border-color: $button-link-alt-border-color;
				color: $button-link-alt-color;
			}
		}
		&:before {
			background: $button-link-alt-left-icon;
		}
		&:after {
			background: $button-link-alt-right-icon;
		}
		&:hover {
			.button-wrapper {
				background:$button-link-alt-hover-bg;
				border-color: $button-link-alt-hover-border-color;
				color: $button-link-alt-hover-color;
			}
			&:before {
				background: $button-link-alt-left-hover-icon;
			}
			&:after {
				background: $button-link-alt-right-hover-icon;
			}
		}
	}
}

@mixin button-link-alt2() {
	a.button,
	.button a,
	form#mc-embedded-subscribe-form .button {
		.button-wrapper {
			background:$button-link-alt2-bg;
			border-color: $button-link-alt2-border-color;
			color: $button-link-alt2-color;
		}
		&:link, &:visited {
			.button-wrapper {
				background:$button-link-alt2-bg;
				border-color: $button-link-alt2-border-color;
				color: $button-link-alt2-color;
			}
		}
		&:before {
			background: $button-link-alt2-left-icon;
		}
		&:after {
			background: $button-link-alt2-right-icon;
		}
		&:hover {
			.button-wrapper {
				background:$button-link-alt2-hover-bg;
				border-color: $button-link-alt2-hover-border-color;
				color: $button-link-alt2-hover-color;
			}
			&:before {
				background: $button-link-alt2-left-hover-icon;
			}
			&:after {
				background: $button-link-alt2-right-hover-icon;
			}
		}
	}
}


// /* TOP MENU button-states
// /* BUTTON LINKS */
@mixin button-top-menu-default() {
	a {
		.button-wrapper {
			background:$button-link-default-bg;
			border-color: $button-link-default-border-color;
			color: $button-link-default-color;
		}
		&:link, &:visited {
			.button-wrapper {
				background:$button-link-default-bg;
				border-color: $button-link-default-border-color;
				color: $button-link-default-color;
			}
		}
		&:before {
			background: $button-link-default-left-icon;
		}
		&:after {
			background: $button-link-default-right-icon;
		}
		&:hover {
			.button-wrapper {
				background:$button-link-alt-hover-bg;
				border-color: $button-link-alt-hover-border-color;
				color: $button-link-alt-hover-color;
			}
			&:before {
				background: $button-link-alt-left-hover-icon;
			}
			&:after {
				background: $button-link-alt-right-hover-icon;
			}
		}
	}
}

@mixin button-top-menu-alt() {
	a {
		.button-wrapper {
			background:$button-link-default-hover-bg;
			border-color: $button-link-default-hover-border-color;
			color: $button-link-default-hover-color;
		}
		&:link, &:visited {
			.button-wrapper {
				background:$button-link-default-hover-bg;
				border-color: $button-link-default-hover-border-color;
				color: $button-link-default-hover-color;
			}
		}
		&:before {
			background: $button-link-default-left-hover-icon;
		}
		&:after {
			background: $button-link-default-right-hover-icon;
		}
		&:hover {
			.button-wrapper {
				background:$button-link-alt-hover-bg;
				border-color: $button-link-alt-hover-border-color;
				color: $button-link-alt-hover-color;
			}
			&:before {
				background: $button-link-alt-left-hover-icon;
			}
			&:after {
				background: $button-link-alt-right-hover-icon;
			}
		}
	}
}