html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
	color:$primary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$title-font-family;
	}
	&.card-title-sm {
		font-family:$title-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}

.palette-highlight {
	h1, h2, h3, h4, h5, h6 {
		color:white;
	}
}

h1, h2 {
	font-weight:normal;
	letter-spacing: 1px;
}

h3, h4, h5, h6 {
	font-weight:normal;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$title-font-family;
	}
}

h1 {
	font-size:2.333rem;
	line-height:1.375;
	margin-bottom:$vert-space;
	
	@include media($normal) {
		font-size:2.778rem;
		line-height: 1.33333333333333;
	}
}

h2 {
	font-size:2.111rem;
	line-height:1.375;
	margin-bottom:0.5em;
	//text-transform: uppercase;

	@include media($normal) {
		font-size:2.333rem;
		line-height: 1.33333333333333;
	}
}

.front .postscript_first {
	h2 .card-title { 
		margin-bottom: 50px;
		.field-content {
			border-bottom: 4px solid $turquoise_surf;
			padding-bottom: 10px;
		}
	}
	.view-header h2 {
    border-bottom: solid 4px $turquoise_surf;
    padding-bottom: 10px;
    display: inline-block;
    margin-bottom: 40px;
	}
	.palette-highlight {
		.card-title .field-content,
		.view-header h2 {
			border-color: white;
		}
	}
	.palette-alt-3 {
		.card-title .field-content,
		.view-header h2 {
			border-color: $primary;
		}
	}
}

aside {
	.side-row > .section-inner > .card-title,
	.side-row .view:not(.views-section) > .view-header,
	.side-row.block-views {
		text-align: center;

		h2 {
			display: inline-block;
			margin-bottom: $vert-space;
			text-align: center;

			&:after {
				content: "";
				display: block;
				width: 80px;
				height: 4px;
				background-color: $turquoise_surf;
		    margin: 5px auto 0;
			}	
		}
	}
	
	 .side-row.block-views {
		 text-align: inherit;
		 
		 & > .card-title,
		 h2 {
		 	text-align: center;
		 }		 
	 }
	
	.side-row.block .view:not(.views-section) {
		.views-row > * {
			font-size: 0.778rem;
	    margin-bottom: $vert-space/2;
		}
		.card-label {
			font-size: 1rem;
		}
	}
	
	.side-row.rhs-menu-block {
		ul.menu {
			margin: $vert-space 0 $vert-space/2;
			padding-left: 0;
			text-decoration: none;
			list-style: none;
			text-align: left;
			
			font-size: 1.222em;
	    line-height: 1.375;
	    font-family:$title-font-family;
			
			li {
				margin-bottom: $vert-space;
				&.last {
					margin-bottom: 0;
				}
			}
		}
	}
}

h3 { 
	font-size:1.667rem;
	line-height:1.375;
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:1.889rem;
		line-height: 1.33333333333333;
	}
}

h3.block__title {
	font-size:1.667rem;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.889rem;
	}
}

h4 {
	font-size:1.444rem;
	line-height:1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.667rem;
		line-height: 1.33333333333333;
	}
}

h5 {
	font-size:1.222rem;
	line-height:1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.444rem;
		line-height: 1.42105263157895;
	}
}

h6 {
	font-size:1.111rem;
	line-height:1.375;
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.222rem;
		line-height: 1.42105263157895;
	}
}

p {
	margin:0 0 $vert-space;
}

main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.778em;
				line-height: 1.5;
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.222rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;
			p {
				font-size: 0.778em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.778rem;
					line-height: 1.5;
				}
			}
		}
	}
}

b, strong {
	font-weight:600;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:0.778rem;
	font-style:normal;
}


em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}

sup { vertical-align: super; }
sub { vertical-align:sub; }


// QUOTES

blockquote {
	position: relative;
	max-width: 90%;
  margin-left: auto;
  margin-right: auto;
	padding: 50px 0 30px 90px;
	p {
		padding:0 10px 0 20px;
		font:300 1.555em/1 $title-font-family;
		position: relative;
		z-index: 2;
	}
	&:before {
		content:"";
		display:inline-block;
		height:37px;
		width:46px;
		position: absolute;
		background:$quote-space no-repeat 50% 50% / 46px 37px;
		left:10px;
		top:10px;
		z-index: 3;
	}
	&:after {
		content:"";
		display: inline-block;
		height:200px;
		width: 200px;
		position: absolute;
		background:$circle-bg-surf no-repeat 50% 50% / 200px 200px;
		top:0;
		left: 0;
		z-index: 1;
	}
}

.side-row {
	blockquote {
		padding: 60px 0 0 0;
		&:after {
			background-size:160px 160px;
			height:160px;
			width:160px;
			@media(min-width:$normal) {
				background-size:200px 200px;
				height:200px;
				width:200px;
			}
		}
	}
}

.postscript-first {
	.palette-alt-3 {
		.card-body {
			blockquote {
				&:after {
					background:$circle-bg-white no-repeat 50% 50% / 200px 200px;
				}
			}
		}
	}
	.palette-highlight {
		.card-body {
			blockquote {
				&:before {
					background:$quote-white no-repeat 50% 50% / 46px 37px; 
				}
			}
		}
	}
}