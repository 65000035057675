.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

.views-exposed-form {
  padding: 20px;
  margin-bottom: 40px;
	background:white;
	label {
		color: $raisin_black;
		font: normal 1.222em/1.3 $title-font-family;
		margin:10px 0 20px;
	}
	.views-exposed-widget .form-submit {
		margin-top:29px;
	}
}

.form-item-keywords input.form-text{
	&::-webkit-input-placeholder {
		color: $primary;
		font-size:0.777em;
		padding:3px 10px 6px;
		margin:0;
		margin-top:-5px;
	}
}

// RESPONSIVE STYLING

.views-exposed-form {
	.views-exposed-widget {
		width:100%;
		padding:0;
		&.views-submit-button,
		&.views-reset-button {
			width:calc(100% - 10px);
			min-width:110px;
			.form-submit {
				width:100%;
				margin-bottom: 10px;
				margin-top:5px;
			}
		}
		&.views-reset-button .form-submit {
			margin-bottom: 0;
		}
		
		padding-right: 20px;
		@media(min-width:540px) {
			width:50%;
			&:nth-child(2),
			&:nth-child(5),
			&:nth-child(7) {
				padding-right:0;
			}
			&.views-submit-button,
			&.views-reset-button {
				// width:25%;
				float:left;
				.form-submit {
					margin-bottom: 0px;
					margin-top:10px;
				}
			}
		}
		@media(min-width:960px) {
			width:33.3%;
			&:nth-child(3),
			&:nth-child(7) {
				padding-right:0;
			}
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(5),
			&:nth-child(6) {
				padding-right:20px;
			}
			&.views-submit-button,
			&.views-reset-button {
				width:auto;
				padding-right:10px;
			}
		}
		@media(min-width:1200px) {
			width:16.5%;
			&:nth-child(7) {
				padding-right:0;
			}
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(6) {
				padding-right:20px;
			}
		}
		.container-inline-date .form-item {
			width:100%;
			.date-padding {
				width:100%
			}
		}
		select {
			width: 100%;
		}
	}
}

// SIDEBAR

.side-row .views-exposed-form  {
	label {
		margin:10px 0 20px;
	}
	.views-exposed-widget-submit {
		@media(min-width:$narrow) {
			flex-direction: column;
		}
		@media(min-width:1100px) {
			flex-direction: row;
		}
	}
		
	.views-exposed-widget {
		width:100%;
		padding:0;
		&.views-submit-button,
		&.views-reset-button {			
			width:calc(50% - 5px);
			margin-top:10px;
			
			@media(min-width:$narrow) {
				width:100%;
			}
			@media(min-width:1100px) {
				width:calc(50% - 5px);
			}
		}
		&.views-submit-button {
			margin-right:10px;
			
			@media(min-width:$narrow) {
				margin-right:0;
			}
			@media(min-width:1100px) {
				margin-right:10px;
			}
		}
		select { width:100%;}
	}
}

//CONTENT MAIN / ADDITIONAL + SIDEBAR

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.views-exposed-form {
			.views-exposed-widget-submit {
		    width:100%;
				float:right;
				@media(min-width:960px) {
					width:50%;
				}
				@media(min-width:1200px) {
					width:34%;
				}
			}
			.views-exposed-widget {
				&.views-submit-button,
				&.views-reset-button {
					width:100%;
				}
				@media(min-width:540px) {
					&.views-submit-button,
					&.views-reset-button {
						// width:calc(25% - 10px);
						width: auto;
					}
				}
				&.views-submit-button {
					margin-top: 20px;
					padding-right: 10px;
					@media(min-width: 540px) {
						margin-top:0;
						// padding-right: 0;
					}
				}
				&.views-reset-button {
					padding-right:0;
					margin-top: 20px;
					padding-left: 10px;
					@media(min-width: 540px) {
						padding-left: 0;
						margin-top:0;
					}
				}
				@media(min-width:960px) {
					width:50%; 
					&:nth-child(2),
					&:nth-child(5),
					&:nth-child(7) {
						padding-right:0;
					}
					&:nth-child(3),
					&:nth-child(6) {
						padding-right:20px;
					}
				}
				@media(min-width:1200px) {
					width:33%;
					&:nth-child(3) {
						padding-right:0;
					}
					&:nth-child(2),
					&:nth-child(4),
					&:nth-child(5) {
						padding-right:20px;
					}
					&.views-reset-button {
						padding-right:6px;
					}					
					&.views-submit-button,
					&.views-reset-button {
						// width:calc(16.5% - 10px);
						width: auto;
					}					
				}
			}
		}
	}
}

.views-exposed-widget-submit {
	display:flex;
	justify-content: flex-end;
	clear:both;
	.views-submit-button {
		order:1;
	}
	.views-reset-button {
		order:2;
	}
}

// .views-exposed-widget {
// 	&.views-submit-button,
// 	&.views-reset-button {
// 		input[type="submit"] {
// 		font: normal 1.111em/1.3 $title-font-family;
// 		background: wheat;
// 		height:48px;
// 		padding: 0;
// 		border-radius: $form-border-radius;
// 			&:hover {
// 				color:$turquoise_surf;
// 				background:$button-border-surf no-repeat 50% 50% / 100px 48px;
// 			}
// 		}	
// 	}
// 	&.views-submit-button input[type="submit"] {
// 		color:$vivid_burgundy;
// 		background:$button-border-burgunday no-repeat 50% 50% / 100px 48px;
// 	}
// 	&.views-reset-button input[type="submit"] {
// 		color:$skibeloff;
// 		background:$button-border-skibeloff no-repeat 50% 50% / 100px 48px;
// 	}
// }

// Palette styles

.palette-default,
.palette-alt-3,
.palette-highlight {
	.views-exposed-form {
	   background-color:$light_cyan;
		 
		 select,
		 #{$all-text-inputs},
		 select[multiple=multiple],
		 textarea {
		 	background-color: white;
 			color:$primary;
		 }
		 
		 input::placeholder {
			 color:$aurometalsaurus !important;
		 }
	}
}

// DISPLAY YEAR FILTER AND HIDE DEFAULT FROM-TO FILTERS
// #views-exposed-form-publications-all,
// #views-exposed-form-resources-all,
// #views-exposed-form-resources-publications-all {
//   #edit-from-wrapper,
//   #edit-to-wrapper {
//     display: none !important;;
//   }
//   #edit-year-wrapper {
//     display: block !important;
//   }
// }