.page-user-:not(.page-user-edit) {
	
	// #breadcrumb {
	// 	.breadcrumb .last {
	// 		display: inline-block;
	// 	}
	// }
		
	main {
		background-color: $light_cyan;
		
		#page-title {
			display: none;
		}
		#custom-page-title {
			.view-display-id-display_name {
				display: inline-block;
			}
		}
		section#content {
			.content-main,
			.content-additional {
				padding: 0;
				@include media(880px) {
					padding: 0;
				}
			}
		}

		.account-image-block {
			text-align: center;
			margin-bottom: -10px;
			
			.profile-default-image {
				content: "";
		    display: inline-block;
		    width: 100%;
				background: $profile-default-image  no-repeat scroll top center transparent;
				background-size:100%;
				max-width: 380px;
				height: calc((100vw/3) * 4 - 50px);
				
				@media screen and (min-width:420px) {					
			    height: 508px;
				}
				
				@media screen and (min-width:$narrow) {					
			    height: 360px;
				}
				
				@media screen and (min-width:1600px) {					
			    height: 508px;
				}
			}
		}
		
		@media screen and (min-width:$narrow) {
		
			.content-main {
		    display: flex;
				display: flex;
    		flex-wrap: wrap;
				
				h1 {
			    flex: 0 0 100%;
				}
				
				section.account-image-block {
			    max-width: 270px;
					width: 30%;
					
					@media screen and (min-width:1600px) {
						max-width: 380px;
					}
				}
				
				section:not(.account-image-block)  {
					width: 70%;
					flex-grow: 1;
					// width: calc(100% - 270px);					
					// 
					// @media screen and (min-width:1600px) {
					// 	width: calc(100% - 380px);
					// }
				}
			}
		}
		
		.account-header-row {
			.account-card-text {
				
				@media screen and (min-width:540px) and (max-width:$narrow), screen and (min-width:$wide) {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
			    margin-left: -20px;
					width: calc(100% + 40px);
					
					& > * {
						width: 50%;
				    padding: 0 20px;
					}
				}
				
				& + .card-biography-44 {
					margin-top: 10px;
				}
			}
		}

		aside#sidebar-second {	
			@media screen and (min-width:$narrow) {	
				margin-top: 57px;
				margin-bottom: $vert-space;
			}
			@media screen and (min-width:$normal) {	
				margin-top: 65px;
			}
			
			section.block {
				margin-bottom: $vert-space*1.5;
				margin-top: 20px;
				
				@media screen and (min-width:$narrow) {	
				// 	margin-top: 77px;
				 	margin-bottom: $vert-space;
				}
				// @media screen and (min-width:$normal) {	
				// 	margin-top: 85px;
				// }
				
				&:last-child {
					margin-bottom: 0;	
				}
			}
		}
	}
	
	#personal-cf,
	form.contact-form {
		fieldset.captcha.form-wrapper {
			border: none;
			margin: -20px 0 10px;
			padding: 0;
		}
	}
	
	@media screen and (min-width:$normal) {	
		&.no-sidebars:not(.page-user-contact) {
			main {
				background-image: $banner-circle-default;
				background-repeat: no-repeat;
		    background-position: calc(100% - 10%) 30px;
		    background-size: 520px;

				.account-header-row {
					&.view-display-id-header_others {
						margin-right: calc((100vw / 12) * 3);
					}
				}
			}
		}
	} 

	section.postscript-first{
		background-color: $light_cyan;
		
		.postscript_first {
			@include outer-container($site-max-width);
			padding:$section-inner-mobile-padding;
			padding: 0 10px;
			@media screen and (min-width:$narrow) {
				padding:$section-inner-padding;
			}
						
			section.block {
				margin-bottom: $vert-space*1.5;
				
				@media screen and (min-width:$narrow) {
					margin-right: $horz-space;
					float: left;
    			width: calc(100%/3 - 40px/3);
					display: block;
					
					&:last-child {
						margin-right: 0;	
					}
				}
			}
		}
	}	

	// #content > h1 {
	// 	display: none;
	// }
	
	.account-row {
		background-color: white;
		padding: $vert-space*1.5 $horz-space*2;
    word-break: break-word;
		
		.view-header {			
			background-color: $platinum;
			padding: $vert-space*2 $horz-space*2;
			margin: -#{$horz-space*1.5} 0 $vert-space*1.5 -#{$horz-space*2};
    	width: calc(100% + #{$horz-space*4});
						
			h2 {
				margin-bottom: 0;
				font-family:$title-font-family;
				font-size: 1.444rem;
				color: $primary;
			}
		}
		
		.view-content {
			.account-card-text,
			.account-card-bio {		
				color: $primary;
				font-size: 1rem;
				font-weight: normal;
				
				& > * {
					margin-bottom: $vert-space;
					
					&:last-child {
						margin-bottom: 0;
					}
				}
				
				.card-email {					
					font-weight: normal;
				}
				
				.label {
					font-family:$title-font-family;
					font-size: 1.222rem;
					color: $black_coral;
					font-weight: normal;
				}
			}
			.card-biography-44 {
				margin-top: $vert-space;
				margin-bottom: $vert-space/2;
			}
		}
	}
	
	.update-btn {
		@include button-top-menu-alt();
		margin-top: $vert-space*1.5;
  } 
}
