.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
		border-bottom: 4px solid $nav-wrapper-bg-color;
		@include media(880px) {
			border-bottom: 0;
		}
	}
	
	@include media(880px) {
		border: none;
	}
	
	// /* Change .header-outer-wrapper to
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	
	.header-inner-wrapper {
		@include outer-container($site-max-width);
		position: relative;
	}
	
	#main-navigation {
		@include outer-container($site-max-width);
		position: relative;
		.search-toggle-wrapper {
			position: absolute;
			top:-86px;
			right: 86px;
			display: block;
			@media(min-width: 880px) {
				top:0;
				right: 0;
			}
			.search-toggle {
				height: 86px;
				width: 86px;
				background: $search-dark no-repeat 50% 50%/ 40px 40px transparent;
				text-indent: -9999px;
				@media(min-width: 880px) {
					height: 46px;
					width: 46px;
					background: $search-white no-repeat 50% 50%/ 24px 24px transparent;
				}
				@media(min-width: 1151px) {
					height: 58px;
					width: 58px;
				}
				&:hover {
					background: $search-blue no-repeat 50% 50%/ 40px 40px transparent;
					color: white;
    			cursor: pointer;
					@media(min-width: 880px) {
						background: $search-dark no-repeat 50% 50%/ 24px 24px $turquoise_surf;
					}
				}
				&.active {
					background: $cross-white no-repeat 50% 50%/ 40px 40px $midnight_green;
					color: white;
					@media(min-width: 880px) {
						background: $cross-white no-repeat 50% 50%/ 24px 24px $midnight_green;
					}
					&:hover {
						background: $cross-white no-repeat 50% 50%/ 40px 40px $turquoise_surf;
						color: white;
						@media(min-width: 880px) {
							background: $cross-white no-repeat 50% 50%/ 24px 24px $turquoise_surf;
						}
					}
				}
			}
		}
		.block-search {
			position: absolute;
			display: block;
			right: 0;
			@media(max-width: 879px) {
				width:100%
			}
			#search-block-form--2 { 
				display: none;
				position: absolute;
				right: 0;
				top: 0;
				background: $midnight_green;
				z-index: 1;
				padding: 5px;
				height:60px;
				max-width: none;
				@media(max-width: 879px) {
					width:100%
				}
				.form-item {
					margin:0;
					@media(max-width: 879px) {
						width:100%
					}
				}
				.form-item-search-block-form input.form-text {
					width:320px;
					background: white;
					border-radius: 0;
					// border-bottom: solid 2px white;
					height: 50px;
					color: $primary;
					@media(max-width: 879px) {
						width:100%;
						max-width: none;
					}
					&::placeholder {
						color:$secondary;
					}
				}
				.form-wrapper {
					display:block;
					@include media(880px) {
						display:block;
					}
				}
				h2 {
					display: none;
				}
				input[type="submit"] {
					text-indent: -9999px;
					position: absolute;
					top: 6px;
					right: 10px;

					background: $search-red no-repeat 50% 50%/ 25px 25px transparent;
					&:hover {
						background: $search-blue no-repeat 50% 50%/ 25px 25px transparent;
					}
				}
				@media(min-width: 880px) {
					top: 46px;
				}
				@media(min-width: 1151px) {
					top:58px;
				}
			}
		}
	}
	
	.header-inner-wrapper {	
		@media (max-width:879px) {
	    display: flex;
	    flex-direction: column-reverse;
		}
		
		.wrapper-site-branding {
			float: left;
			
			.site-branding {
				//margin-top:2.5rem;
				margin-left:1.25rem;
				position: relative;
				// @include media(880px) {
				// 	top:0;
				// }
				.site-title {
					// position:absolute;
					// left:0;
					margin-top:0;
					
					@include media(880px) {
						margin-top:20px;
					}
					
					a {
						background-size:contain;
						display:block;
						text-indent:-999em;
						overflow:hidden;
						width:200px;
						height:80px;
						
						max-width: calc(100% - 86px - 86px - 10px);
						background-position: 0 100%;
						
						@include media(540px) {
							width:280px;
							height:110px;
							max-width: none;
						}
					}
				}
				
				.site-slogan {
					position:absolute;
					left:235px;
					top:2.5rem;
				}
			}
		}
		
		.wrapper-region-header {
			float: right;
			display: flex;
			justify-content: flex-end;
    	flex-direction: column;
			flex-wrap: wrap;
			align-items: flex-end;
			
			@include media(570px) {
    		flex-direction: row;
				flex-wrap: wrap;
    		align-items: normal;
			}
						
			.block-search {
				display: none !important;
				
				height:10rem;
				@include media(880px) {
					height:auto;
				}
				
				h2 {
					display: none;
				}
				.form-item-search-block-form {
					margin-top:40px;
					margin-right:1.125rem;
					
					@media (min-width: 1150px) {
						margin-right:1.5625rem;
					}
					
					margin-bottom:30px;
					float:left;
					
					input.form-text {
						width:10rem;
						height:1.875rem;
						background-color:$header-search-field-bg-color;
						color:$header-search-field-text-color;
						box-shadow:$header-search-field-box-shadow;
						border:$header-search-field-border;
						border-radius:$header-search-field-border-radius;
						font:$header-search-field-font;
						padding:0.375rem 0.75rem;
					}
				}
				.form-actions {
					float:left;
					margin-top:0.75rem;
					input.form-submit {
						background: transparent $search-dark no-repeat scroll 0 0;
						background-size:14px;
						width:0.875rem;
						height:0.875rem;
						text-indent:-999em;
						overflow:hidden;
						padding:0;
						position:absolute;
						right:1.25rem;
						margin-right:0.325rem;
						
						@media (min-width: 1150px) {
							margin-right:0.7625rem;
						}
						
						top:50px;
						border: none;
					}
				}
			}

			.block-menu {
				
				@include media(880px) {
					margin-top:24px;
				}
				
				ul.menu {
					
					margin: 10px 0 0px;
					
					@include media(570px) {
						margin: 20px 0 20px;
					}
					
					li {
						display:inline-block;
						margin:0 0.75rem 0 0;
						
						@include button-top-menu-alt();			
					}

					a {
						font:$header-menu-font;
					}
				}			
				
				&.block-system {				
					ul.menu {
						padding-left: 0;
						margin: 5px 0px 5px 20px;
						
						// @include media(500px) {
						// 	margin: 15px 0px 10px 20px;
						// }
						
						@include media(570px) {
							margin: 20px 0 20px 5px;
						}
						
						@media (max-width: 570px) {
							li a {
								&::before, &::after {
									display: none;
								}
								.button-wrapper {
									padding: 0.25em 1rem;
									border: 4px solid;
  							  border-radius: 30px;
								}
							}
						}
						
						li:last-child {					
							@include button-top-menu-default();
						}
					}
				}	
				
				&:not(.block-system) {
					ul.menu li a {
						@media (max-width: 1020px) {
							&::before, &::after {
								display: none;
							}
							
							.button-wrapper {
								border: none;
								margin-top: 5px;								
								color: $skibeloff;								
							}
							
							&:hover .button-wrapper {
								color: $action-hover;
							}
						}
					}
				}
			}
			
			.block-locale {
				clear:both;
				display:none;
			}
		}
	}
}
