// /* BANNER PALETTE */
$banner-title-font               : 3.556rem/1.125 $title-font-family;
$banner-summary-font             : 1.444rem/1.125 $base-font-family;

$banner-narrow-title-font        : 3.000rem/1.125 $title-font-family;
$banner-narrow-summary-font      : 1.333rem/1.125 $base-font-family;

$banner-mobile-title-font        : 2.333rem/1.125 $title-font-family;
$banner-mobile-summary-font      : 1.222rem/1.125 $base-font-family;

$banner-default-bg               : white;

$banner-bg-color-style-1         :$light_cyan;
$banner-color-style-1            :$primary;
$banner-bg-image-style-1         :$banner-circle-default;
$banner-bg-shape-style-1         :$banner-curve-default;

$banner-bg-color-style-2         :white;
$banner-color-style-2            :$primary;
$banner-bg-image-style-2         :$banner-circle-default;
$banner-bg-shape-style-2         :$banner-curve-alt1;

$banner-bg-color-style-3         :white;
$banner-color-style-3            :$primary;
$banner-bg-image-style-3         :$banner-circle-alt1;
$banner-bg-shape-style-3         :$banner-curve-alt2;

$banner-bg-color-style-4         :white;
$banner-color-style-4            :$primary;
$banner-bg-image-style-4         :$banner-circle-alt2;
$banner-bg-shape-style-4         :$banner-curve-alt3;

$banner-bg-color-style-5         :white;
$banner-color-style-5            :$primary;
$banner-bg-image-style-5         :$banner-circle-alt1;
$banner-bg-shape-style-5         :$banner-curve-highlight;